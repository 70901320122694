import React, {useEffect, useState, useRef} from 'react';
import { useParams, Link } from "react-router-dom";
import ProjectsTable from '../../components/ProjectsTable';
import trix from 'trix/dist/trix';
import { TrixEditor } from "react-trix";
import moment from 'moment';

const useForceUpdate = () => useState()[1];

export default function ManagePublication({ sessionValid, urlBase }) {
  const [profile, setProfile] = useState({});
  const [ editProfile, setEditProfile ] = useState({});
  const [ loading, setLoading] = useState(true);
  const [ userHasAccess, setUserHasAccess ] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [ projects, setProjects ] = useState([]);
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);

  const [ newDescription, setNewDescription] = useState('');
  const [ newStatus, setNewStatus ] = useState('');

  const [ editingEditors, setEditingEditors ] = useState(false);
  const [newEditors, setNewEditors] = useState([]);
  const [addingUser, setAddingUser] = useState('');
  const [addingNewUserError, setAddingNewUserError] = useState('');

  const [newWebsite, setNewWebsite] = useState('');
  const [newTwitter, setNewTwitter] = useState('');
  const [newGram, setNewGram] = useState('');
  const [newCountry, setNewCountry] = useState('');
  const [newCity, setNewCity] = useState('');

  const [uploading, setUploading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  let { id } = useParams();

  useEffect(() => {
    getPublicationProfile();
   }, []);

   const token = localStorage.getItem('firmamentToken');

   const getPublicationProfile = async () => {
     let data = await fetch(`${urlBase}manage-pub?pub=${id}&token=${token}`);
     let response = await data.json();
     setLoading(false);
     if (response.status === 'error') {
       setUserHasAccess(false);
     } else {
       setUserHasAccess(true);
       setIsOwner(response.isOwner);
       setProfile(response.profile);
       setProjects(response.projects);
     }
   }

   const fileInput = useRef(null);
   const forceUpdate = useForceUpdate();
   const [file, setFile] = useState('');
   const { current } = fileInput;

   const toggleEditing = () => {
     if (!editing) {
       setNewStatus(profile.status);
       setNewDescription(profile.description);
       setNewGram(profile.instagram);
       setNewWebsite(profile.website);
       setNewTwitter(profile.twitter);
       setNewCity(profile.city);
       setNewCountry(profile.country);
       setEditing(true);
     } else {
       setEditing(false);
     }
   }

   const toggleEditingEditors = () => {
     if (!editingEditors) {
       setNewEditors(profile.editors);
       setEditingEditors(true);
     } else {
       setEditingEditors(false);
       setNewEditors([]);
     }
   }

   const saveChanges = async () => {
     let changes = {
       description: newDescription,
       twitter: newTwitter,
       instagram: newGram,
       website: newWebsite,
       status: newStatus,
       country: newCountry,
       city: newCity,
       token: token,
       id: profile._id,
       logo: profile.logo
     };
     //
     if (fileName()) {
       let logo = await uploadLogo();
       changes.logo = logo;
     }
     //
     const settings = {
       method: 'POST',
       headers: {
         Accept: 'application/json',
         'Content-Type': 'application/json',
       },
       body: JSON.stringify(changes)
     };
     const response = await fetch(`${urlBase}update-pub`, settings);
     const data = await response.json();
     setSaving(false);
     setEditing(false);
     getPublicationProfile();
   }


   const uploadLogo = async () => {
     setUploading(true);
     let originalName = fileName();
     let originalParts = originalName.split('.');
     let extension = originalParts[originalParts.length - 1];
     const { current } = fileInput;
     let file = current.files[0];
     const formData = new FormData();
     formData.append('file', file);
     let response = await fetch(`${urlBase}logo?pub=${id}&ext=${extension}`, {
       method: 'post',
       body: formData,
     });
     let data = await response.json();
     return data.file;
     setUploading(false);
   }


   const saveChangesEditors = async () => {
     let changes = {
       editors: newEditors,
       token: token,
       id: profile._id
     };
     const settings = {
       method: 'POST',
       headers: {
         Accept: 'application/json',
         'Content-Type': 'application/json',
       },
       body: JSON.stringify(changes)
     };
     const response = await fetch(`${urlBase}update-pub-editors`, settings);
     const data = await response.json();
     setSaving(false);
     setEditingEditors(false);
     getPublicationProfile();
   }

   const tryAddingUser = async () => {
     setAddingNewUserError('');
     if (newEditors.indexOf(addingUser) !== -1) {
       setAddingNewUserError('That user is already an editor');
       return;
     }
     const response = await fetch(`${urlBase}check-user-exists?user=${addingUser}`);
     const data = await response.json();
     if (!data.exists) {
       setAddingNewUserError('That user does not exist');
     } else {
       let newestEditors = newEditors;
       newestEditors.push(addingUser.replace(/\s/g, ''));
       setNewEditors(newestEditors);
       setAddingUser('');
     }
   }

   const removeEditor = (ed) => {
     let newestEditors = newEditors.filter((value, index, arr) => { return value !== ed;});
     setNewEditors(newestEditors);
   }

   const fileName = () => {
     if (current && current.files.length > 0) {
       return current.files[0].name;
     }
     return null;
   }

   const filePreview = () => {
     if (current && current.files.length > 0) {
       return URL.createObjectURL(current.files[0]);
     }
     return null;
   }


  return (
    <div>
      {(!sessionValid || !userHasAccess) && <span>You must <Link to={`/login?redirect=publication/${id}/manage`}>login</Link> to view this page</span>}
      {sessionValid && userHasAccess &&
        <div>
          <div className='page-header with-logo'>
            {profile.logo && <div className='pub-logo'><img src={profile.logo} /></div>}
            <div className='page-info'>
              <div className='meta-info'>Joined {moment(profile.created).format('LL')}</div>
              {profile.name && <h1>{profile.name}</h1>}
              <div><Link to={`/publication/${profile.slug}`} >View Profile</Link></div>
            </div>
          </div>
          <div className='main-container'>
            <div className='main-left'>
              <div className='module-head'>Profile</div>
              <div className='module full'>
              {!editing &&
                <div>
                  <div className='input-label'>Status</div>
                  <div className='description'>{profile.status && profile.status}</div>
                  <div className='input-label'>Press Description</div>
                  <div className='description' dangerouslySetInnerHTML={{__html: profile.description}}></div>
                  <div className='input-label'>City</div>
                  <div className='description'>{profile.city}</div>
                  <div className='input-label'>Country</div>
                  <div className='description'>{profile.country}</div>
                  <div className='input-label'>Official Website</div>
                  <div className='description'>{profile.website}</div>
                  <div className='input-label'>Official Twitter</div>
                  <div className='description'>@{profile.twitter}</div>
                  <div className='input-label'>Official Instagram</div>
                  <div className='description'>@{profile.instagram}</div>
                </div>
              }
              {editing &&
                <div>
                  <div className='input-label'>Status</div>
                  <select className='w-60' value={newStatus} onChange={(e) => setNewStatus(e.target.value)}>
                    <option value='Active'>Active</option>
                    <option value='inactive'>Inactive</option>
                  </select>
                  <div className='input-label'>Logo</div>
                  <input type='file' type='file' ref={fileInput} onChange={forceUpdate} disabled={uploading || submitting ? 'disabled' : ''} />
                  {!uploading && !submitting &&
                    <label htmlFor="file" className={`file-upload`} onClick={() => {fileInput.current.click()}}>
                      <span tabIndex="0" role="button" aria-controls="filename">
                        Select File
                      </span>
                    </label>
                  }
                  <div className=""></div>
                  <div className='logo-preview'>
                    {!filePreview() && profile.logo && <img className='logo-preview-img' src={profile.logo} />}
                    {filePreview() && <img className='logo-preview-img' src={filePreview()} />}
                  </div>
                  <div className='input-label'>Press Description</div>
                  <TrixEditor value={newDescription} onChange={(html, text) => setNewDescription(html)} />
                  <div className='input-label'>City</div>
                  <input value={newCity} onChange={(e) => setNewCity(e.target.value)} />
                  <div className='input-label'>Country</div>
                  <input value={newCountry} onChange={(e) => setNewCountry(e.target.value)} />
                  <div className='input-label'>Website</div>
                  <input value={newWebsite} onChange={(e) => setNewWebsite(e.target.value)} />
                  <div className='input-label'>Twitter</div>
                  <input value={newTwitter} onChange={(e) => setNewTwitter(e.target.value)} />
                  <div className='input-label'>Instagram</div>
                  <input value={newGram} onChange={(e) => setNewGram(e.target.value)} />
                </div>
              }
              {!editing && <button onClick={toggleEditing}>Edit</button>}
              {editing && <div className='controls'><button onClick={saveChanges}>Save</button> <button className='cancel' onClick={toggleEditing}>Cancel</button></div>}
              </div>
            </div>

            <div className='sidebar-right'>
            <div className='module-head'>People</div>
            <div className='module full'>
              <div className='input-label'>Account Owner</div>
              <div className='description'>{profile.owner}</div>

              <div className='input-label'>Editors {!editingEditors && isOwner && <div className='edit-link' onClick={toggleEditingEditors}>Edit Users</div>}</div>
              <div className='description'>
                {!editingEditors && profile.editors && profile.editors.length === 0 && <span className='fyi'>There are no editors assigned to this publication</span>}
                {!editingEditors && profile.editors && profile.editors.length > 0 && profile.editors.join(', ')}
                {editingEditors &&
                  <div>
                    <div>
                      <div className='controls'><input value={addingUser} onChange={(e) => setAddingUser(e.target.value)} /><button onClick={tryAddingUser}>Add</button></div>
                      {addingNewUserError && <span className='error small'>{addingNewUserError}</span>}
                      {newEditors.length > 0 && newEditors.map(ed =>
                        <div key={ed} className='editor-pill'><span onClick={() => removeEditor(ed)}>X</span> {ed}</div>
                      )}
                    </div>
                  </div>
                }
              </div>
              {editingEditors && isOwner && <div className='controls'><button onClick={saveChangesEditors}>Save</button> <button className='cancel' onClick={toggleEditingEditors}>Cancel</button></div>}
            </div>
            </div>

          </div>

          <div className='module-head'><span>Projects</span> <button><Link to={`/publication/${id}/add-project`}>Create A Project</Link></button></div>
          <ProjectsTable projects={projects} context='edit' />
        </div>
      }
    </div>
  )
}
