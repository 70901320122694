import React, { useState } from 'react';
import { useHistory, Link } from "react-router-dom";

export default function Login({ checkSession, sessionValid, logout, urlBase }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [checking, setChecking] = useState(false);
  const [error, setError] = useState('');

  let history = useHistory();

  const login = async () => {
    setChecking(true);
    const user = {
      email: email,
      password: password
    };
    const settings = {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(user)
    };
    const response = await fetch(`${urlBase}login`, settings)
    const data = await response.json();
    if (data.status == 'error') {
      setChecking(false);
      setError(data.message);
    } else {
      localStorage.setItem('firmamentToken', data.token);
      checkSession();
      const params = new URLSearchParams(window.location.search)
      const redirect = params.has('redirect') ? params.get('redirect') : 'my-account';
      history.push(`/${redirect}`)
    }
  }

  return (
    <div className='main-container'>
      {sessionValid &&
        <div>You are already logged in. Vist <Link to={'/my-account'}>your account</Link> page, or <a onClick={logout}>log out</a> to sign in to another account.</div>
      }
      {!sessionValid &&
        <div className='module w-40 auth'>
          <h1 className='auth-header'>Login</h1>
          <span>Need an account? <Link to={'/create-account'}>Create one</Link></span>

          <div className='input-label'>Email</div>
          <input className='w-100' value={email} onChange={(e) => {setEmail(e.target.value)}} />
          <div className='input-label'>Password</div>
          <input className='w-100' value={password} onChange={(e) => {setPassword(e.target.value)}} type='password' />
          {!checking && <button onClick={login}>Login</button>}
          {error && <span className='error'>{error}</span>}

          <Link className='help-link' to={'/forgot-password'}>Forgot Password?</Link>
        </div>
      }
    </div>
  )
}
