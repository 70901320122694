import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

export default function ProjectsTable({projects, context}) {

  const isOpen = (project) => {
    if (project.type === "Rolling" || project.type == "Quota") {
      return true;
    } else if (project.type == "Start") {
      //
      if (moment().isAfter(project.start)) {
        return true;
      } else {
        return false;
      }
    } else if (project.type == "End") {
      if (moment().isBefore(project.end)) {
        return true;
      } else {
        return false;
      }
    } else if (project.type == "Range") {
      if (moment().isAfter(project.start) && moment().isBefore(project.end)) {
        return true;
      } else {
        return false;
      }
    }
  }

  const isOpening = (project) => {
    if (project.type === "Range" || project.type === "Start") {
      return moment().isBefore(project.start);
    } else {
      return false;
    }
  }

  return(
    <div>
      <div className='subs-table'>
        {projects && projects.map(project =>
          <div key={project._id} className="sub-row">
            <span className='sub-title'>{project.name}
              <div className='admin-links'>
                <Link to={`/publication/${project.publication.slug}/${project.slug}`}>View</Link>
                <Link to={`/publication/${project.publication.slug}/${project.slug}/manage`}>Edit</Link>
                <Link to={`/publication/${project.publication.slug}/${project.slug}/submissions`}>Submissions</Link>
              </div>
            </span>
            <span>
              <span>{project.type}</span>
              {project.type == 'Quota' && <div className='type-info'>{project.quota} submissions</div>}
              {project.type == 'Range' && <div className='type-info'>{moment(project.start).format('LLL')}-{moment(project.end).format('LLL')} </div>}
              {project.type == 'Start' && <div className='type-info'>Starting {moment(project.start).format('LLL')}</div>}
              {project.type == 'End' && <div className='type-info'>Ending {moment(project.end).format('LLL')} </div>}
            </span>
            <span>{isOpen(project) && <div className='project-status-pill open'>Now Open</div>}
            {isOpening(project) && <div className='project-status-pill soon'>Opens Soon</div>}
            {!isOpen(project) && !isOpening(project) && <div className='project-status-pill closed'>Closed</div>}</span>
            <span><div className='proj-status-label'>{project.status === 'Active' ? 'Visible' : 'Hidden'}</div></span>
          </div>
        )}
      </div>
      {!projects && <span>You don't have any projects yet</span>}
      {projects && <span>Showing {projects.length} {projects.length == 1 ? 'project' : 'projects'}</span>}
    </div>
  )
}
