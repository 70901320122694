import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function Home({urlBase}) {
  const [publications, setPublications] = useState([])

  const getPublications = async () => {
    let data = await fetch(`${urlBase}list-pubs`);
    let response = await data.json();
    setPublications(response.pubs);
  }

  useState(() => {
    getPublications();
  }, [])

  return (
    <div>
      <div className='beta-alert'>Publisher accounts are currently in beta only. Want to try out Oleada? Get in touch for an invite at: rothes@sublunaryeditions.com</div>
      <div className='pub-list'>
        {publications && publications.map((pub) =>
          <div className='module pub-mod'>
            <div className='pub-home'>
              <Link to={`/publication/${pub.slug}`}>{pub.logo ? <div className='pub-home-logo'><img src={pub.logo} /></div> : <div className='pub-home-logo'><img src='https://oleada.io/default-oleada.png' /></div>}</Link>
              <div className='pub-home-info'>
                <div className='pub-home-meta'>{pub.city}</div>
                <Link to={`/publication/${pub.slug}`} className='pub-home-info-title'>{pub.name}</Link>

              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
