import React from 'react';
import { Link } from 'react-router-dom';

export default function About() {
  document.title = 'About | Sublunary Editions';

  return(
    <div>
      <h1>About</h1>
      <div className='main-container'>
        <div className='main-left'>
          <div className='module-head'>Features</div>
          <div className='subs-table'>

            <div className="sub-row">
              <span className='sub-title'>Unlimited projects per publication</span>
              <span className='feature-desc'>There are no limits on how many projects you can create as a publication. Need a new project each month? No problem.</span>
            </div>

            <div className="sub-row">
              <span className='sub-title'>Unlimited editors per publication</span>
              <span className='feature-desc'>Whether you're a one-person team or have a whole platoon of editors, we don't put caps on how many users you can add to your publication to review submissions.</span>
            </div>

            <div className="sub-row">
              <span className='sub-title'>Five different ways to take submissions</span>
              <span className='feature-desc'>When creating a project, you have the options to take Rolling submissions (i.e. always open), implement a Quota (i.e. the project closes once you receive a certain number of submissions), start on a specific date/time, end on a specific date/time, or choose a time range.</span>
            </div>

            <div className="sub-row">
              <span className='sub-title'>Anonymize submissions</span>
              <span className='feature-desc'>For each project, you have the option to anonymize submissions, meaning you will not see any biographical data until a piece is Accepted or Declined.</span>
            </div>

            <div className="sub-row">
              <span className='sub-title'>Security is key</span>
              <span className='feature-desc'>We encrypt all files we store, so even if our data storage was breached, authors' works would be protected.</span>
            </div>

            <div className="sub-row">
              <span className='sub-title'>Multi-level editorial process</span>
              <span className='feature-desc'>Move submissions through "Quick Read" and "Close Read" phases.</span>
            </div>

            <div className="sub-row">
              <span className='sub-title'>Have editorial conversations about submissions</span>
              <span className='feature-desc'>Each submission has a comment thread for editors only, so you and your team can talk back and forth.</span>
            </div>

            <div className="sub-row">
              <span className='sub-title'>Message with Authors</span>
              <span className='feature-desc'>Publications and authors can send messages back and forth after a submission has been set to a "Final" status, i.e. Accepted, Declined, Withdrawn. Authors can get email notifications when they get new messages. Either party can stop the message thread at any time.</span>
            </div>

            <div className="sub-row">
              <span className='sub-title'>No submission fees for authors. Period.</span>
              <span className='feature-desc'>We wanted to be a low-cost alternative for publishers in part to lessen the need for submission fees. We believe submission fees are often a barrier to working class writers, a burden than falls unevenly on people of color.</span>
            </div>

          </div>
          <div className='module-head'>Limitations / Future Features</div>
          <div className='subs-table'>

            <div className="sub-row">
              <span className='sub-title'>Workflow Customization</span>
              <span className='feature-desc'>One of the features we anticipate launching sooner rather than later is the ability to customize the "steps" in your editorial workflow.</span>
            </div>

            <div className="sub-row">
              <span className='sub-title'>File upload limitations</span>
              <span className='feature-desc'>Users can submit one file per submission, currently. We plan to expand this in the near future. Currently supported file types are: .pdf, .docx, .doc, .rtf, .txt, .png, .jpg. You can choose what file types you want to accept on a per-project basis.</span>
            </div>

            <div className="sub-row">
              <span className='sub-title'>Strict form logic</span>
              <span className='feature-desc'>Right now, you won't have much flexibility on the submission form, such as adding custom fields or choosing what is required. We do plan to add this in the future.</span>
            </div>

            <div className="sub-row">
              <span className='sub-title'>Notifications are limited</span>
              <span className='feature-desc'>The only notifications we have built in presently are for authors who receive messages from publications. There are no notifications yet for new submissions, though again, this will likely change in the near future.</span>
            </div>

          </div>


        </div>
        <div className='sidebar-right'>
        <div className='module-head'>About</div>
        <div className='module'>
          <p><strong>Oleada</strong> was founded to be a flexible, affordable tool for small presses, magazines, and websites. It was developed by Joshua Rothes for Sublunary Editions. It is currently in beta. If you are the editor/publisher/owner of a publication that would like
          to be informed of when we will open for customers, have any questions, etc., get in touch at rothes@sublunaryeditions.com</p>
          <p>Want to see what we're working on in more detail? Check out the <Link to='/roadmap'>roadmap</Link>.</p>
        </div>
        </div>
      </div>
    </div>
  )
}
