import React from 'react';

export default function Roadmap() {
  return(
    <div>
      <h1>Roadmap</h1>
      <div className='main-container'>
        <div className='main-left'>

        <div className='module-head'>Recently Added</div>
          <div className='subs-table'>
              <div className='sub-table-header'>
                <span className='flex-half'>Type</span>
                <span>Issue</span>
                <span className='flex-two'>Description</span>
                <span>Estimated Fix Date</span>
                <span>Status</span>
              </div>

              <div className="sub-row">
                <span className='flex-half'>Feature</span>
                <span className='sub-title'>Email notifications for new submissions</span>
                <span className='flex-two'>Allow publishers to designate a list of email addresses to get notified when a project gets a new submission.</span>
                <span className=''>July 2020</span>
                <span>Done</span>
              </div>

            </div>

          <div className='module-head'>In Progress</div>
            <div className='subs-table'>
                <div className='sub-table-header'>
                  <span className='flex-half'>Type</span>
                  <span>Issue</span>
                  <span className='flex-two'>Description</span>
                  <span>Estimated Fix Date</span>
                  <span>Status</span>
                </div>

                <div className="sub-row">
                  <span className='flex-half'>Feature</span>
                  <span className='sub-title'>Define a custom editorial flow</span>
                  <span className='flex-two'>Allow publishers to create up 10 phases for their editorial workflow.</span>
                  <span className=''>August 2020</span>
                  <span>In Progress</span>
                </div>

              </div>

            <div className='module-head'>Coming Soon</div>

            <div className='subs-table'>
                <div className='sub-table-header'>
                  <span className='flex-half'>Type</span>
                  <span>Issue</span>
                  <span className='flex-two'>Description</span>
                  <span>Estimated Fix Date</span>
                  <span>Status</span>
                </div>

                <div className="sub-row">
                  <span className='flex-half'>Feature</span>
                  <span className='sub-title'>On-screen notifications</span>
                  <span className='flex-two'>Don't want email notifications? See notifications when your submissions change status or when you receive a message on your screen when you log in.</span>
                  <span className=''>September 2020</span>
                  <span>Coming Soon</span>
                </div>

              </div>

            <div className='module-head'>Further</div>

            <div className='subs-table'>
                <div className='sub-table-header'>
                  <span className='flex-half'>Type</span>
                  <span>Issue</span>
                  <span className='flex-two'>Description</span>
                  <span>Estimated Fix Date</span>
                  <span>Status</span>
                </div>

                <div className="sub-row">
                  <span className='flex-half'>Feature</span>
                  <span className='sub-title'>Customizable form logic</span>
                  <span className='flex-two'>Determine which fields you want to require, and add your own custom fields to the submission page.</span>
                  <span className=''>TBD</span>
                  <span>Further</span>
                </div>

              </div>

        </div>
        <div className='sidebar-right'>
        <div className='module full'>
          <p>See what we're working on.</p>
        </div>
        </div>
      </div>
    </div>
  )
}
