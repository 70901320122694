import React, { useEffect, useState } from 'react';
import { useHistory, Link, BrowserRouter as Router, useLocation } from "react-router-dom";
import SubsTable from '../../components/SubsTable';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function MyAccount({ sessionValid, checkSession, urlBase }) {
  const [profile, setProfile] = useState({});
  const [publications, setPublications] = useState([]);
  const [submissions, setSubmissions] = useState([]);
  let history = useHistory();
  const query = useQuery();

  const token = localStorage.getItem('firmamentToken');

  useEffect(() => {
     if(sessionValid && !profile._id) {
       getData();
     }
   });

  const getData = async () => {
    let response = await fetch(`${urlBase}get-user?token=${token}`);
    let data = await response.json();
    setProfile(data.profile);
  }

  return (
    <div>
      {sessionValid &&
        <div>
          <div className='page-header'>
            <Link to={`/my-account`} className='breadcrumb'>&larr; My Account</Link>
            <h1>My Submissions</h1>
          </div>
          <SubsTable context='user' urlBase={urlBase} query={query} />
        </div>
      }
      {!sessionValid && <span>Not authorized</span>}

    </div>
  )
}
