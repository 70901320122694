import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import moment from 'moment';
import trix from 'trix/dist/trix';
import { TrixEditor } from "react-trix";

export default function EditSubmission({ sessionValid, urlBase }) {
  let { id } = useParams();

  const [sub, setSub] = useState({});
  const [error, setError] = useState('');
  const [newComment, setNewComment] = useState('');
  const [viewing, setViewing] = useState(false);

  const [projectProfile, setProjectProfile] = useState({});

  const [editing, setEditing] = useState(false);
  const [newStatus, setNewStatus] = useState('');
  const [newMessage, setNewMessage] = useState('');

  const token = localStorage.getItem('firmamentToken');

  useEffect(() => {
    if (token) {
      getSub();
    } else {
      setError('True')
    }
  }, [])

  const getSub = async () => {
    let response = await fetch(`${urlBase}get-sub-edit?token=${token}&id=${id}`);
    let data = await response.json();
    if (data.status === 'error') {
      setError(data.message)
    } else {
      setSub(data.sub);
      setNewStatus(data.sub.status);
      getProjectProfile(data.sub.publication.slug, data.sub.project.slug)
    }
  }

  const getProjectProfile = async (id, project) => {
    let response = await fetch(`${urlBase}get-project-edit?pub=${id}&project=${project}&token=${token}`);
    let data = await response.json();
    setProjectProfile(data.project);
  }

  const toggleEditing = () => {
    if (!editing) {
      setEditing(true);
      setNewStatus(sub.status);
    } else {
      setEditing(false);
      setNewStatus('');
      setNewMessage('');
    }
  }

  useEffect(() => {
    console.log(sub.status, newStatus)
  }, [newStatus])

  const comment = async () => {
    let data = {
      token: token,
      comment: newComment,
      id: id
    };
    const settings = {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    };
    const response = await fetch(`${urlBase}add-comment`, settings);
    const responseData = await response.json();
    if (responseData.status == 'good') {
      getSub('');
      setNewComment('');
    }
  }

  const saveStatus = async () => {
    let data = {
      token: token,
      status: newStatus,
      id: id
    };
    const settings = {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    };
    const response = await fetch(`${urlBase}change-status`, settings);
    const responseData = await response.json();
    console.log(newStatus, newComment.length)
    if ((newStatus == 'Accepted' || newStatus == 'Declined') && newMessage.length > 0) {
      sendMessage();
    }
    if (responseData.status == 'good') {
      setEditing(false);
      setNewMessage('');
      getSub('');
    }
  }

  const sendMessage = async () => {
    let data = {
      token: token,
      message: newMessage,
      editor: true,
      id: id
    };
    const settings = {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    };
    const response = await fetch(`${urlBase}add-message`, settings);
    const responseData = await response.json();
    if (responseData.status == 'good') {
      setNewMessage('');
      getSub('');
    }
  }

  const changeStatus = (newstatus) => {
    setNewStatus(newstatus);
    setNewMessage(projectProfile.standardDecline);
  }

  const isImage = (fileName) => {
    let lower = fileName.toLowerCase();
    return lower.includes('.jpg') || lower.includes('.jpeg') || lower.includes('.png') || lower.includes('.gif');
  }

  return(
    <div>
      {sub._id &&
        <div>

          <div className='page-header'>
            <Link to={`/publication/${sub.publication.slug}/${sub.project.slug}/submissions`} className='breadcrumb'>&larr; Back to submissions</Link>
            <h1>"{sub.title}"</h1>
            <div>Submitted to <Link to={`/publication/${sub.publication.slug}/${sub.project.slug}`}>{sub.project.name}</Link> on {moment(sub.submittedDate).format('LLLL')}</div>
          </div>

          <div className='main-container'>
            <div className='module main-left'>

            <div className='input-label'>Submission Status</div>
            {sub.status === 'Withdrawn' &&
              <div>
                <div className='description'>{sub.status}</div>
              </div>
             }
             {sub.status !== 'Withdrawn' &&
               <div>
                <select value={newStatus} onChange={(e) => changeStatus(e.target.value)}>
                  <option value='Submitted'>Submitted</option>
                  <option value='Quick Read'>Quick Read</option>
                  <option value='Close Read'>Close Read</option>
                  <option value='Accepted'>Accepted</option>
                  <option value='Declined'>Declined</option>
                  <option value='Withdrawn'>Withdrawn</option>
                </select>
                {(newStatus == 'Declined' || newStatus == 'Accepted') && (!sub.messages || sub.messages.length == 0) &&
                  <div>
                    <div className='input-label'>Message to User</div>
                    <TrixEditor value={newMessage} onChange={(html, text) => setNewMessage(html)} />
                  </div>
                }
                {newStatus !== sub.status && <div className='controls extra-padding-bottom'><button onClick={saveStatus}>Save</button> <button className='cancel' onClick={() => {setNewStatus(sub.status)}}>Cancel</button></div>}
               </div>
             }

              <div className='input-label'>Author</div>
              <div className='description'>{sub.anonymous && (sub.status !== 'Accepted' && sub.status !== 'Declined' && sub.status !== 'Withdrawn') ? 'Anonymous' : sub.user.name}</div>
              <div className='input-label'>Email</div>
              <div className='description'>{sub.anonymous && (sub.status !== 'Accepted' && sub.status !== 'Declined' && sub.status !== 'Withdrawn') ? '--' : sub.user.id}</div>
              <div className='input-label'>Cover Letter</div>
              <div className='description' dangerouslySetInnerHTML={{__html: sub.coverLetter}}></div>
              <div className='input-label'>Bio</div>
              {sub.anonymous && (sub.status !== 'Accepted' && sub.status !== 'Declined' && sub.status !== 'Withdrawn') ? <div className='description'>--</div> : <div className='description' dangerouslySetInnerHTML={{__html: sub.bio}}></div>}
              <div className='input-label'>Submission File</div>
              <div className='description'>
                <button className='secondary inline' onClick={() => {setViewing(viewing ? false : true)}}><a>{viewing ? 'Hide' : 'Preview'}</a></button>
                <button className='secondary inline'><a href={sub.fileLocation} download target='_blank'>Download</a></button>
              </div>
              {viewing &&
                <div>
                  {isImage(sub.fileLocation) ? <img src={sub.fileLocation} width="80%" height="800" /> :
                  sub.fileLocation.includes('.mp3') ? <audio controls="controls" src={sub.fileLocation}></audio> : <iframe src={`https://docs.google.com/gview?url=${encodeURIComponent(sub.fileLocation)}&embedded=true`} width="80%" height="800" />}
                </div>
              }
            </div>

            <div className='sidebar-right'>
              <div className='module full message-bar'>
                 <div className='input-label'>Messages With Author</div>
                 {((sub.messages.length > 0 || (sub.status == 'Accepted' || sub.status == 'Declined')) && !editing) &&
                   <div>
                      <TrixEditor value={newMessage} onChange={(html, text) => setNewMessage(html)} />
                      {newMessage.length > 0 && <button onClick={sendMessage}>Send</button>}
                   </div>
                 }
                 {sub.messages.length > 0 && sub.messages.sort((a,b) => {
                   return new Date(b.time) - new Date(a.time);
                 }).map(message =>
                   <div key={message.time} className={`message ${!message.user.editor && 'highlight'}`}>
                     <div className='comment-text' dangerouslySetInnerHTML={{__html: message.text}}></div>
                     <div className='comment-meta'>by {message.user.name} on {moment(message.time).format('LLLL')}</div>
                   </div>
                 )}
                 {sub.messages.length == 0 && <div className='fyi'>There are no messages yet.</div>}

               </div>
            </div>


          </div>
          <div className='main-left'>
            <h2>Comments (Editorial team only)</h2>
            <TrixEditor key={sub.comments.length} value={newComment} onChange={(html, text) => setNewComment(html)} />

            {newComment.length > 0 && <button onClick={comment}>Comment</button>}
            {sub.comments.length > 0 && sub.comments.sort((a,b) => {
              return new Date(b.time) - new Date(a.time);
              }).map(comment =>
              <div key={comment.time} className='comment'>
                <div className='comment-text' dangerouslySetInnerHTML={{__html: comment.text}}></div>
                <div className='comment-meta'>by {comment.user.name} on {moment(comment.time).format('LLLL')}</div>
              </div>
            )}
          </div>
        </div>
      }
      {error && <span>You do not have access to view this submission. Try <Link to={`/login?redirect=submission/${id}`}>logging in</Link></span>}
    </div>
  )
}
