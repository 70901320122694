import React, { useEffect, useState } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';

import SubsTable from '../../components/SubsTable';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ProjectSubmissions({sessionValid, urlBase}) {
  const [submissions, setSubmissions] = useState([]);
  const [userHasAccess, setUserHasAccess] = useState(false);
  const [projectData, setProjectData] = useState({});
  const [publicationData, setPublicationData] = useState({});
  const [loading, setLoading] = useState(false);

  const query = useQuery();

  useEffect(() => {
    if (sessionValid && !loading) {
      setLoading(true);
      getSumissionsForProject();
    }
  })

  let {project} = useParams();
  let {id} = useParams();

  const getSumissionsForProject = async () => {
    const token = localStorage.getItem('firmamentToken');
    let data = await fetch(`${urlBase}get-project-submissions?project=${project}&token=${token}&pub=${id}`);
    let response = await data.json();
    if (response.status == 'error') {
    } else {
      setUserHasAccess(true);
      setProjectData(response.project);
      setPublicationData(response.publication);
    }
  }

  return(
    <div>
      {(!sessionValid || !userHasAccess) && <span>You must <Link to={`/login?redirect=publication/${id}/${project}/submissions`}>login</Link> to view this page</span>}
      {sessionValid && userHasAccess && projectData.name &&
        <div>
          <div className='page-header'>
            <Link to={`/publication/${projectData.publication.slug}/${projectData.slug}/manage`} className='breadcrumb'>&larr; Edit Project</Link>
            <h1>Submissions for {projectData.name}</h1>
            <div>by {projectData.publication.name}</div>
          </div>
          {projectData._id &&
            <SubsTable
              context='edit'
              anonymous={projectData.anonymous}
              project={projectData.slug}
              pub={projectData.publication.slug}
              urlBase={urlBase}
              query={query}
            />
          }
        </div>
      }
    </div>
  )
}
