import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";

export default function ResetPassword({ checkSession, sessionValid, logout, urlBase }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [checking, setChecking] = useState(false);
  const [error, setError] = useState('');
  const [tokenValid, setTokenValid] = useState(false);
  const [tokenError, setTokenError] = useState('');
  const [errorFields, setErrorFields] = useState([]);

  let history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    if (!params.has('passchange')) {
      setTokenError('Your password reset token is missing');
    } else {
      let passtoken = params.get('passchange');
      verifyToken(passtoken)
    }
  }, []);


  const verifyToken = async (passtoken) => {
    const response = await fetch(`${urlBase}check-reset-token?token=${passtoken}`)
    const data = await response.json();
    if (!data.valid) {
      setTokenError('This token is no longer valid. Please try again.')
    } else {
      setTokenError('');
      setEmail(data.email);
      setTokenValid(true);
    }
  }


  const changePass = async () => {
    setChecking(true);
    const user = {
      email: email,
      password: password
    };
    const settings = {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(user)
    };
    const response = await fetch(`${urlBase}reset-pass`, settings)
    const data = await response.json();
    if (data.status == 'error') {
      setChecking(false);
      setError(data.message);
    } else {
      localStorage.setItem('firmamentToken', data.token);
      checkSession();
      history.push(`/my-account`)
    }
  }

  const validateForm = () => {
    let valid = true;
    let theerror = '';
    let problems = [];
    if (password.length < 6) {
      valid = false;
      problems.push('password1')
      theerror = 'Password must be at least 6 characters.';
    } else if (password !== passwordConfirm) {
      valid = false;
      problems.push('password2')
      theerror = 'Passwords do not match';
    }
    if (valid) {
      setError('');
      setErrorFields([]);
      changePass();
    } else {
      setError(theerror)
      setErrorFields(problems);
    }
  }

  return (
    <div className='main-container'>
      {sessionValid &&
        <div>You are already logged in. Vist <Link to={'/my-account'}>your account</Link> page, or <a onClick={logout}>log out</a> to sign in to another account.</div>
      }
      {!tokenValid && tokenError &&
        <span className='error'>This token is no longer valid or missing. Please <Link to={'/forgot-password'}>try again</Link></span>
      }
      {!sessionValid && tokenValid &&
        <div className='module w-40 auth'>
          <h1 className='auth-header'>Reset Your Password</h1>

          <div className='input-label'>Email</div>
          <div className='description'>Resetting password for <strong>{email}</strong></div>
          <div className='input-label'>Password</div>
          <input className={`w-60 ${errorFields.indexOf('password1') !== -1 && 'input-error'}`} value={password} onChange={(e) => {setPassword(e.target.value)}} type='password' />
          <div className='input-label'>Confirm Password</div>
          <input className={`w-60 ${errorFields.indexOf('password2') !== -1 && 'input-error'}`} type='password' value={passwordConfirm} onChange={(e) => {setPasswordConfirm(e.target.value)}} />

          {!checking && <button onClick={validateForm}>Update Password</button>}
          {error && <span className='error'>{error}</span>}

        </div>
      }
    </div>
  )
}
