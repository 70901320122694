import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

export default function UserMenu({ profile, publications, email, logout, showUserMenu, setShowUserMenu }) {

  const location = useLocation();

  const prevLocationRef = useRef();

  useEffect(() => {
    if (prevLocationRef.current) {
      setShowUserMenu(false);
    }
    prevLocationRef.current = location.pathname;
  }, [location]);

  const prevLocation = prevLocationRef.current;

  return(
    <div className='profile-menu'>
      <div className='item'>
        <div className='profile-menu-name'>{profile.name}</div>
        <div className='profile-menu-email'>{email}</div>
      </div>
      <Link className='item' to="/my-account">Profile</Link>
      <Link className='item' to='/my-submissions'>My Submissions</Link>
      {publications && publications.map((pub) =>
        <Link key={pub.slug} className='item' to={`/publication/${pub.slug}/manage`}>{pub.name}</Link>
      )}
      <a className='item' onClick={logout}>Log Out</a>
    </div>
  )
}
