import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useLocation
} from "react-router-dom";

import Home from './pages/Home';
import Login from './pages/Login';
import CreateAccount from './pages/CreateAccount';
import CreatePublication from './pages/CreatePublication';
import MyAccount from './pages/MyAccount';
import MySubmissions from './pages/MySubmissions';
import PublicationHome from './pages/PublicationHome';
import ManagePublication from './pages/ManagePublication';
import CreateProject from './pages/CreateProject';
import ProjectHome from './pages/ProjectHome';
import SubmissionPage from './pages/SubmissionPage';
import ViewSubmission from './pages/ViewSubmission';
import EditSubmission from './pages/EditSubmission';
import ProjectSubmissions from './pages/ProjectSubmissions';
import About from './pages/About';
import Roadmap from './pages/Roadmap';
import EditProject from './pages/EditProject';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import ChangePassword from './pages/ChangePassword';
import { ReactComponent as Logo } from './logo.svg';

import UserMenu from './components/UserMenu';

import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) {
    history.replace(path);
}

export default function App() {
  const [sessionValid, setSessionValid] = useState(false);
  const [error, setError] = useState('');
  const [username, setUsername] = useState('');
  const [name, setName] = useState('')
  const [showMenu, setShowMenu] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [role, setRole] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [publications, setPublications] = useState([]);
  const [profile, setProfile] = useState({});

  const urlBase = 'https://api.oleada.io:3001/';
  // const urlBase = 'http://localhost:3001/'

  useEffect(() => {
    checkSession();
  }, [])

  const getUserData = async (token) => {
    let response = await fetch(`${urlBase}get-user?token=${token}`);
    let data = await response.json();
    setProfile(data.profile);
    setPublications(data.publications);
  }

  const checkSession = async () => {
    const token = localStorage.getItem('firmamentToken');
    if (!token) {
      setSessionValid(false);
      setUsername('');
    } else {
      try {
        let validate = await fetch(`${urlBase}validate?token=${token}`);
        let data = await validate.json();
        if (!data.valid) {
          setSessionValid(false);
          localStorage.setItem('firmamentToken', '');
          setUsername('');
          setRole('');
        } else {
          setSessionValid(true);
          setUsername(data.user);
          setName(data.name);
          getUserData(token);
          // checkNotifications();
        }
      } catch(err) {
        setError('There was a problem communicating with the server.');
      }
    }
  }

  const checkNotifications = async () => {
    const token = localStorage.getItem('firmamentToken');
    try {
      let response = await fetch(`${urlBase}notifications?token=${token}`);
      let data = await response.json();
    } catch(err) {
      setError('There was a problem communicating with the server.')
    }
  }

  const logout = () => {
    localStorage.setItem('firmamentToken', '');
    checkSession();
    // history.push('/');
  }

  return (
    <Router>
      <div className="app-container">
        <div className="header">
        <div className='header-left'>
          <div className='header-logo'><Link to="/"><Logo /></Link></div>
        </div>
        {sessionValid &&
          <div className='header-right'>
            <span className='clickable' onClick={() => {setShowUserMenu( showUserMenu ? false : true); setShowMenu(false);}}>My Account</span>
            <span className='desktop-hide more-menu' onClick={() => {showMenu ? setShowMenu(false) : setShowMenu(true); setShowUserMenu(false);}}>...</span>
            <div className='extended-menu desktop'>
              <span><Link to="/about">About</Link></span>
            </div>
            {showMenu && <div className='extended-menu mobile'>
              <span><Link to="/about">About</Link></span>
            </div>}
            {showUserMenu && <UserMenu profile={profile} publications={publications} email={username} logout={logout} showUserMenu={showUserMenu} setShowUserMenu={setShowUserMenu} />}
          </div>
        }
        {!sessionValid &&
          <div className='header-right'>
            <span><Link to="/login">Login</Link></span>
            <span className='desktop-hide more-menu' onClick={() => {showMenu ? setShowMenu(false) : setShowMenu(true)}}>...</span>
            <div className='extended-menu desktop'>
              <span><Link to="/create-account">Create Account</Link></span>
              <span><Link to="/about">About</Link></span>
            </div>
            {showMenu && <div className='extended-menu mobile'>
              <span><Link to="/create-account">Create Account</Link></span>
              <span><Link to="/about">About</Link></span>
            </div>}
          </div>
        }
        </div>
        <div className="main-page">
        {error && <div className='global-error'>{error}</div>}
        <Switch>
          <Route exact path="/">
            <Home urlBase={urlBase} />
          </Route>
          <Route path="/login">
            <Login checkSession={checkSession} sessionValid={sessionValid} logout={logout} urlBase={urlBase} />
          </Route>
          <Route path="/forgot-password">
            <ForgotPassword checkSession={checkSession} sessionValid={sessionValid} logout={logout} urlBase={urlBase} />
          </Route>
          <Route path="/reset-password">
            <ResetPassword checkSession={checkSession} sessionValid={sessionValid} logout={logout} urlBase={urlBase} />
          </Route>
          <Route path="/change-password">
            <ChangePassword checkSession={checkSession} sessionValid={sessionValid} logout={logout} urlBase={urlBase} />
          </Route>
          <Route path="/create-account">
            <CreateAccount checkSession={checkSession} sessionValid={sessionValid} urlBase={urlBase} />
          </Route>
          <Route path="/my-account">
            <MyAccount checkSession={checkSession} sessionValid={sessionValid} urlBase={urlBase} />
          </Route>
          <Route path="/my-submissions">
            <MySubmissions checkSession={checkSession} sessionValid={sessionValid} urlBase={urlBase} />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/roadmap">
            <Roadmap />
          </Route>
          <Route path="/create-publication">
            <CreatePublication sessionValid={sessionValid} urlBase={urlBase} />
          </Route>
          <Route path="/publication/:id/:project/submit">
            <SubmissionPage sessionValid={sessionValid} urlBase={urlBase} />
          </Route>
          <Route path="/publication/:id/:project/manage">
            <EditProject sessionValid={sessionValid} urlBase={urlBase} />
          </Route>
          <Route path="/publication/:id/:project/submissions">
            <ProjectSubmissions sessionValid={sessionValid} urlBase={urlBase} />
          </Route>
          <Route path="/publication/:id/manage">
            <ManagePublication sessionValid={sessionValid} urlBase={urlBase} />
          </Route>
          <Route path="/publication/:id/add-project">
            <CreateProject sessionValid={sessionValid} urlBase={urlBase} />
          </Route>

          <Route path="/publication/:id/:project">
            <ProjectHome sessionValid={sessionValid} urlBase={urlBase} />
          </Route>
          <Route path="/publication/:id">
            <PublicationHome sessionValid={sessionValid} urlBase={urlBase} />
          </Route>
          <Route path="/submission/:id/edit">
            <EditSubmission sessionValid={sessionValid} urlBase={urlBase} />
          </Route>
          <Route path="/submission/:id">
            <ViewSubmission sessionValid={sessionValid} urlBase={urlBase} />
          </Route>
        </Switch>
        </div>
        <div id="footer">&copy; 2021</div>
      </div>
    </Router>
  );
}
