import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import trix from 'trix/dist/trix';
import { TrixEditor } from "react-trix";
import moment from 'moment';

const slugify = require('slugify');

export default function CreatePublication({sessionValid, urlBase}) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [valid, setValid] = useState('');
  const [slug, setSlug] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');

  let history = useHistory();

  useEffect(() => {
    updateSlug();
    validateForm();
  })

  const updateSlug = () => {
    const slug = slugify(name, {lower: true, strict: true});
    setSlug(slug);
  }

  const validateForm = () => {
    const formValid = name.length > 2 ? true : false;
    setValid(formValid);
  }

  const createPublication = async () => {
    setSubmitting(true);
    setError('');
    const pub = {
      name: name,
      slug: slug,
      description: description,
      token: localStorage.getItem('firmamentToken'),
    };
    const settings = {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(pub)
    };
    const response = await fetch(`${urlBase}create-pub`, settings)
    const data = await response.json();
    setSubmitting(false);
    if (data.status === 'error') {
      setError(data.message);
    } else {
      history.push(`/publication/${slug}/manage`);
    }
  }


  return (
    <div>
      <h1>Create A New publication</h1>
      {sessionValid &&
        <div className='module main-left'>
          <div className='input-label'>Name</div>
          <input value={name} onChange={(e) => setName(e.target.value)} />
          <span className='slug-preview'>URL preview: firmanent.io/publications/{slug}</span>
          <div className='input-label'>Description</div>
          <TrixEditor value={description} onChange={(html, text) => setDescription(html)} />
          {valid && !submitting && <button onClick={createPublication}>Create</button>}
          {submitting && <span>Submitting...</span>}
          {error && <span>{error}</span>}
        </div>
      }
      {!sessionValid && <span>You must be logged in to do this. Click <Link to="/login?redirect=create-publication">here</Link> to log in.</span>}
    </div>
  )
}
