import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";

export default function CreateAccount({checkSession, sessionValid, urlBase}) {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [reason, setReason] = useState('');
  const [error, setError] = useState('');
  const [creating, setCreating] = useState(false);

  const [errorFields, setErrorFields] = useState([]);

  let history = useHistory();

  useEffect(() => {

  })

  const validateForm = () => {
    let valid = true;
    let theerror = '';
    let problems = [];
    if (email === '' || !email.includes('@')) {
      valid = false;
      problems.push('email')
      theerror = 'Must include a valid email';
    } else if (password.length < 6) {
      valid = false;
      problems.push('password1')
      theerror = 'Password must be at least 6 characters.';
    } else if (password !== passwordConfirm) {
      valid = false;
      problems.push('password2')
      theerror = 'Passwords do not match';
    }
    if (valid) {
      setError('');
      setErrorFields([]);
      console.log(email);
      createUser();
    } else {
      setError(theerror)
      setErrorFields(problems);
    }
  }

  const createUser = async () => {
    setCreating(true);
    const newUser = {
      name: name,
      email: email,
      password: password
    };
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(newUser)
    };
    const response = await fetch(`${urlBase}create-user`, settings)
    const data = await response.json();
    setCreating(false);
    if (data.status === 'good') {
      localStorage.setItem('firmamentToken', data.token);
      checkSession();
    } else {
      setError(data.error);
    }
  }

  return (
    <div className='main-container'>
      {!sessionValid &&
        <div className='module w-40 auth'>
          <h1 className='auth-header'>Create Account</h1>
          <span>Already have an account? <Link to={'/login'}>log in</Link></span>
          <div className='input-label w-60'>Name</div>
          <input className='w-60' value={name} onChange={(e) => setName(e.target.value)} />
          <div className='input-label'>Email</div>
          <input className={`w-60 ${errorFields.indexOf('email') !== -1 && 'input-error'}`} value={email} onChange={(e) => setEmail(e.target.value)} />
          <div className='input-label'>Password</div>
          <input className={`w-60 ${errorFields.indexOf('password1') !== -1 && 'input-error'}`} type='password' value={password} onChange={(e) => {setPassword(e.target.value)}} />
          <div className='input-label'>Confirm Password</div>
          <input className={`w-60 ${errorFields.indexOf('password2') !== -1 && 'input-error'}`} type='password' value={passwordConfirm} onChange={(e) => {setPasswordConfirm(e.target.value)}} />
          {error && <span className='error'>{error}</span>}
          {!creating && <button onClick={validateForm}>Create</button>}
          {creating && !error && <span>Loading...</span>}
        </div>
      }
      {sessionValid &&
        <span>You are already logged in.</span>
      }
    </div>
  )
}
