import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import trix from 'trix/dist/trix';
import { TrixEditor } from "react-trix";
import { ReactComponent as Loading } from '../../components/loading.svg';


export default function MyAccount({ sessionValid, checkSession, urlBase }) {
  const [profile, setProfile] = useState({});
  const [publications, setPublications] = useState([]);
  const [submissions, setSubmissions] = useState([]);
  let history = useHistory();

  const [editing, setEditing] = useState(false);
  const [newName, setNewName] = useState('');
  const [newBio, setNewBio] = useState('');
  const [newPronouns, setNewPronouns] = useState('');
  const [newEmails, setNewEmails] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const token = localStorage.getItem('firmamentToken');

  useEffect(() => {
     if(sessionValid && !profile._id) {
       getData();
     }
   });

  const getData = async () => {
    let response = await fetch(`${urlBase}get-user?token=${token}`);
    let data = await response.json();
    setProfile(data.profile);
    setPublications(data.publications);
  }

  const toggleEditing = () => {
    if (!editing) {
      setNewName(profile.name);
      setNewBio(profile.bio);
      setNewEmails(profile.preferences.email.message);
      setNewPronouns(profile.pronouns);
      setEditing(true);
    } else {
      setEditing(false);
    }
  }

  const saveChanges = async () => {
    setSubmitting(true);
    const changes = {
      token: token,
      name: newName,
      bio: newBio,
      pronouns: newPronouns,
      messages: newEmails
    }
    const settings = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(changes)
    };
    const response = await fetch(`${urlBase}update-profile`, settings);
    const data = await response.json();
    setEditing(false);
    setSubmitting(true);
    getData();
  }

  return (
    <div>
      {sessionValid &&
        <div>
          <h1>My Account</h1>
          <div className='main-container'>
            <div className='main-left'>
              <div className='module-head'>Profile & Settings</div>
              <div className='module full'>
                <div className='input-label'>User ID / Email</div>
                <div className='description'>{profile._id}</div>
                {!editing &&
                  <div>
                    <div className='input-label'>Display Name</div>
                    <div className='description'>{profile.name}</div>
                    <div className='input-label'>Pronouns</div>
                    <div className='description'>{profile.pronouns}</div>
                    <div className='input-label'>Bio</div>
                    <div className='description' dangerouslySetInnerHTML={{__html: profile.bio}}></div>
                    <div className='input-label'>Email Notifications for New Messages</div>
                    <div className='description'>{profile.preferences && profile.preferences.email.message ? 'On' : 'Off'}</div>
                    <button onClick={toggleEditing}>Edit</button>
                  </div>
                }
                {editing &&
                  <div>
                    <div className='input-label'>Display Name</div>
                    <input value={newName} onChange={(e) => setNewName(e.target.value)} />
                    <div className='input-label'>Preferred Pronouns</div>
                    <input value={newPronouns} onChange={(e) => setNewPronouns(e.target.value)} />
                    <div className='input-label'>Bio</div>
                    <TrixEditor value={newBio} onChange={(html, text) => setNewBio(html)} />
                    <div className='input-label'>Email Notifications for New Messages</div>
                    <input type="checkbox" checked={newEmails} onChange={(e) => setNewEmails(e.target.checked)} />
                    {!submitting && <div className='controls'><button onClick={saveChanges}>Save</button> <button className='cancel' onClick={toggleEditing}>Cancel</button></div>}
                    {submitting && <div className='loading-icon'><Loading />Saving...</div>}
                  </div>
                }
              </div>
            </div>
            <div className='sidebar-right'>
              <div className='module-head'>My Publications</div>
              <div className='module full'>
              {publications && publications.map((pub) =>
                  <div className='pub-home account' key={pub.slug}>
                    {pub.logo && <div className='pub-home-logo'><img src={pub.logo} /></div>}
                    <div className='pub-home-info'>
                      <div className='input-label'>{pub.role}</div>
                      <div className='pub-home-info-title'>{pub.name}</div>
                      <div className='site-links'><Link to={`/publication/${pub.slug}`}>View</Link> <Link to={`/publication/${pub.slug}/manage`}>Manage</Link></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      }
      {!sessionValid && <span>Not authorized</span>}

    </div>
  )
}
