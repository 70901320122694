import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Link } from "react-router-dom";
import trix from 'trix/dist/trix';
import moment from 'moment';
import { TrixEditor } from "react-trix";
import Datetime from 'react-datetime';
const slugify = require('slugify');

export default function CreateProject({sessionValid, urlBase}) {
  let { id } = useParams();
  let [userHasAccess, setUserHasAccess] = useState({});
  let [profile, setProfile] = useState({});
  let [loading, setLoading] = useState(true);
  let [submitting, setSubmitting] = useState(false);
  let [error, setError] = useState('');
  // Form
  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [description, setDescription] = useState('');
  const [guidelines, setGuidelines] = useState('');
  const [status, setStatus] = useState('Active');
  const [type, setType] = useState('Rolling');
  const [anonymous, setAnonymous] = useState(false);
  const [quota, setQuota] = useState(1);
  const [reqBio, setReqBio] = useState(true);
  const [reqCover, setReqCover] = useState(true);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [filetypes, setFiletypes] = useState({
    allowed: ['pdf', 'doc', 'docx'],
    possible: ['pdf', 'doc', 'docx', 'rtf', 'txt', 'jpg', 'png', 'mp3']
  })

  const possible = ['pdf', 'doc', 'docx', 'rtf', 'txt', 'jpg', 'png','mp3'];

  const token = localStorage.getItem('firmamentToken');

  let history = useHistory();


  useEffect(() => {
    getPublicationProfile();
  }, []);

  const getPublicationProfile = async () => {
    let data = await fetch(`${urlBase}manage-pub?pub=${id}&token=${token}`);
    let response = await data.json();
    setLoading(false);
    if (response.status === 'error') {
      setUserHasAccess(false);
    } else {
      setUserHasAccess(true);
      setProfile(response.profile);
    }
  }

  const changeName = (e) => {
    const name = e.target.value;
    const slug = slugify(name, {lower: true, strict: true});
    setName(name);
    setSlug(slug);
  }

  const [inputErrors, setInputErrors] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const validate = () => {
    let errorFields = [];
    let message = '';
    let thisValid = true;
    if (name.length < 2) {
      errorFields.push('name');
      thisValid = false;
      message += 'Name cannot be empty. '
    }
    if (description.length == 0) {
      errorFields.push('description');
      thisValid = false;
      message += 'Description cannot be empty. '
    }
    if (guidelines.length == 0) {
      errorFields.push('guidelines');
      thisValid = false;
      message += 'Guidelines cannot be empty. '
    }
    if (type == "Quota" && !quota) {
      errorFields.push('quota');
      thisValid = false;
      message += 'You must select a value for quota';
    }
    if (type == "Range" && !startDate) {
      errorFields.push('start');
      thisValid = false;
      message += 'You must select a start date';
    }
    if (type == "Range" && !endDate) {
      errorFields.push('end');
      thisValid = false;
      message += 'You must select an end date';
    }
    if (filetypes.allowed.length == 0) {
      errorFields.push('files');
      thisValid = false;
      message += 'You must select at least one accepted file type. ';
    }
    if (moment(endDate).isBefore(startDate)) {
      errorFields.push('start');
      errorFields.push('end');
      thisValid = false;
      message += 'End date cannot be before start date. ';
    }
    if (type == "End" && !endDate) {
      errorFields.push('end');
      thisValid = false;
      message += 'You must select an end date';
    }
    if (type == "Start" && !startDate) {
      errorFields.push('start');
      thisValid = false;
      message += 'You must select a start date';
    }
    //
    //
    if (thisValid) {
      setInputErrors([]);
      setErrorMessage('');
      createProject();

    } else {
      setInputErrors(errorFields);
      setErrorMessage(message);
    }
  }

  const createProject = async () => {
    setError('');
    setSubmitting(true);
    let newProject = {
      token: token,
      name: name,
      slug: slug,
      publication: {
        id: profile._id,
        name: profile.name,
        slug: id
      },
      description: description,
      guidelines: guidelines,
      created: new Date(),
      anonymous: anonymous,
      requireBio: reqBio,
      requireCover: reqCover,
      filetypes: filetypes.allowed,
      status: status,
      type: type,
      quota: quota,
      start: startDate,
      end: endDate
    }
    const settings = {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(newProject)
    };
    const response = await fetch(`${urlBase}create-project`, settings);
    const data = await response.json();
    setSubmitting(false);
    if (data.status === 'error') {
      setError(data.message);
    } else {
      history.push(`/publication/${id}/${slug}`);
    }
  }

  const toggleFiletype = (type) => {
    const exists = filetypes.allowed.indexOf(type) == -1 ? false : true;
    let newFiletypes = filetypes.allowed;
    if (exists) {
      newFiletypes = newFiletypes.filter((value, index, arr) => { return value !== type;});
    } else {
      newFiletypes.push(type);
    }
    setFiletypes({
      allowed: newFiletypes,
      possible: possible
    });
  }


  return (
    <div>
      {userHasAccess &&
        <div>
          <h1>Create A New Project for <em>{profile.name}</em></h1>

          <div className='main-left'>
            <div className='module create-mod'>
              <div className='input-label'>Name</div>
              <input className={`w-40 ${inputErrors.indexOf('name') !== -1 && 'input-error'}`} value={name} onChange={changeName} />
              {slug && <span className='file-name'>{slug}</span>}


              <div className='input-label'>Description</div>
              <TrixEditor value={description} onChange={(html, text) => setDescription(html)} className={`${inputErrors.indexOf('description') !== -1 && 'input-error'}`} />

              <div className='input-label'>Guidelines</div>
              <TrixEditor value={guidelines} onChange={(html, text) => setGuidelines(html)} className={`${inputErrors.indexOf('guidelines') !== -1 && 'input-error'}`} />

              <div className='input-label'>Allowed File Types</div>
              {filetypes.possible.map((type) =>
                <div key={type} onClick={() => toggleFiletype(type)} className={`editor-pill ${filetypes.allowed.indexOf(type) !== -1 && 'active'}`}>{type}</div>
              )}

              <div className='input-label'>Anonymize Submissions</div>
              <input type="checkbox" checked={anonymous} onChange={(e) => setAnonymous(e.target.checked)} />

              <div className='input-label'>Require Bio</div>
              <input type="checkbox" checked={reqBio} onChange={(e) => setReqBio(e.target.checked)} />

              <div className='input-label'>Require Cover Letter</div>
              <input type="checkbox" checked={reqCover} onChange={(e) => setReqCover(e.target.checked)} />

              <div className='input-label'>Submission Type</div>
              <select value={type} onChange={(e) => setType(e.target.value)}>
                <option value='Rolling'>Rolling</option>
                <option value='Quota'>Quota</option>
                <option value='Range'>Time Range</option>
                <option value='Start'>Start Date</option>
                <option value='End'>End Date</option>
              </select>
              {type === 'Rolling' && <span className='fyi'>Submissions are open by default until manually closed.</span>}
              {type === 'Quota' && <span className='fyi'>Submissions are open until a threshold is hit, i.e. 100 submissions.</span>}
              {type === 'Range' && <span className='fyi'>Submissions are open only between the date/times selected.</span>}
              {type === 'Start' && <span className='fyi'>Submissions are open after the date/time selected.</span>}
              {type === 'End' && <span className='fyi'>Submissions are open until the date/time selected.</span>}
              {type === 'Quota' &&
                <div>
                  <div className='input-label'>Quota</div>
                  <input className={`${inputErrors.indexOf('quota') !== -1 && 'input-error'}`} type='number' value={quota} onChange={(e) => setQuota(e.target.value)} />
                </div>
              }
              {(type == "Range" || type == "Start") &&
                <div>
                  <div className='input-label'>Start Date/Time</div>
                  <Datetime value={startDate} onChange={setStartDate} className={`${inputErrors.indexOf('start') !== -1 && 'input-error'}`} closeOnSelect={true}/>
                </div>
              }
              {(type == "Range" || type == "End") &&
                <div>
                  <div className='input-label'>End Date/Time</div>
                  <Datetime value={endDate} onChange={setEndDate} className={`${inputErrors.indexOf('end') !== -1 && 'input-error'}`} closeOnSelect={true}/>
                </div>
              }
              <div className='input-label'>Status</div>
              <select value={status} onChange={(e) => setStatus(e.target.value)}>
                <option value='Active'>Active</option>
                <option value='Hidden'>Hidden</option>
              </select>
              {status === 'Active' && <span className='fyi'>Users will be able to view the project.</span>}
              {status === 'Hidden' && <span className='fyi'>Only you will be able to see this project.</span>}

              {errorMessage && <div className='form-error error'>{errorMessage}</div>}

              {!submitting && <button onClick={validate}>Create</button>}
              {submitting && <span>Submitting...</span>}
            </div>
          </div>
        </div>
      }
      {

      }
    </div>
  )
}
