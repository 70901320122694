import React, { useState } from 'react';
import { useHistory, Link } from "react-router-dom";

export default function Login({ checkSession, sessionValid, logout, urlBase }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [checking, setChecking] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  let history = useHistory();

  const validate = () => {
    setError('');
    if (password.length == 0) {
      setError('You must fill in your current password');
      return;
    } else if (newPassword.length < 6) {
      setError('Your new password must be at least 6 characters');
      return;
    } else if (newPassword !== newPasswordConfirm) {
      setError('Passwords don\'t match');
      return;
    }
    change();
  }

  const token = localStorage.getItem('firmamentToken');

  const change = async () => {
    setChecking(true);
    const user = {
      token: token,
      password: password,
      newPassword: newPassword
    };
    const settings = {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(user)
    };
    const response = await fetch(`${urlBase}change-password`, settings)
    const data = await response.json();
    if (data.status == 'error') {
      setChecking(false);
      setError(data.message);
    } else {
      setSuccess(true);
    }
  }

  return (
    <div className='main-container'>
      {!sessionValid &&
        <div>You are already logged in. Vist <Link to={'/my-account'}>your account</Link> page, or <a onClick={logout}>log out</a> to sign in to another account.</div>
      }
      {sessionValid && !success &&
        <div className='module w-40 auth'>
          <h1 className='auth-header'>Change Password</h1>

          <div className='input-label'>Current Password</div>
          <input className='w-60' value={password} onChange={(e) => {setPassword(e.target.value)}} type='password' />
          <div className='input-label'>New Password</div>
          <input className='w-60' value={newPassword} onChange={(e) => {setNewPassword(e.target.value)}} type='password' />
          <div className='input-label'>New Password</div>
          <input className='w-60' value={newPasswordConfirm} onChange={(e) => {setNewPasswordConfirm(e.target.value)}} type='password' />
          {!checking && <button onClick={validate}>Change Password</button>}
          {error && <span className='error'>{error}</span>}

        </div>
      }
      {success &&
        <div>You have successfully updated your password!</div>
      }
    </div>
  )
}
