import React, { useEffect, useState } from 'react';
import { useParams, Link } from "react-router-dom";
import moment from 'moment';
import { ReactComponent as Loading } from '../../components/loading.svg';

export default function ProjectHome({ sessionValid, urlBase }) {
  const [userIsOwner, setUserIsOwner] = useState(false);
  const [projectData, setProjectData] = useState({});
  const [publication, setPublication] = useState({});
  const [overQuota, setOverQuota] = useState(false);

  useEffect(() => {
    getProjectData();
  }, []);

  const { id } = useParams();
  const { project } = useParams();

  const getProjectData = async () => {
    const token = localStorage.getItem('firmamentToken');
    let response = await fetch(`${urlBase}get-project?pub=${id}&project=${project}&token=${token}`);
    let data = await response.json();
    setUserIsOwner(data.isOwner || data.isEditor);
    setProjectData(data.project);
    setPublication(data.publication);
  }

  const isOpen = (project) => {
    if (project.type === "Rolling" || project.type == "Quota") {
      return true;
    } else if (project.type == "Start") {
      //
      if (moment().isAfter(project.start)) {
        return true;
      } else {
        return false;
      }
    } else if (project.type == "End") {
      if (moment().isBefore(project.end)) {
        return true;
      } else {
        return false;
      }
    } else if (project.type == "Range") {
      if (moment().isAfter(project.start) && moment().isBefore(project.end)) {
        return true;
      } else {
        return false;
      }
    }
  }

  const isOpening = (project) => {
    if (project.type === "Range" || project.type === "Start") {
      return moment().isBefore(project.start);
    } else {
      return false;
    }
  }


  return (
    <div>
      {!projectData.status && <div className='loading-icon'><Loading />Loading...</div>}
      {projectData.status && projectData.status !== 'Active' && !userIsOwner && <div>You do not have permission to view this page.</div>}
      {(projectData.status == 'Active' || userIsOwner) &&
      <div>
        <div className='page-header'>
          {userIsOwner && projectData.status == 'Hidden' &&  <div className='alert'>You can view this page because you are an editor on the project. Other users will not be able to see this until the project status is changed to "Visible".</div>}
          <h1 className='page-title-header'>{projectData.name && projectData.name}
            <span>
              {isOpen(projectData) && <div className='project-status-pill open'>Now Open</div>}
              {isOpening(projectData) && <div className='project-status-pill soon'>Opens Soon</div>}
              {!isOpen(projectData) && !isOpening(projectData) && <div className='project-status-pill closed'>Closed</div>}
            </span>
          </h1>
          <span>{userIsOwner && <span><Link to={`/publication/${id}/${project}/manage`}>Edit</Link> | </span>} by {publication.name &&<Link to={`/publication/${publication.slug}`}>{publication.name}</Link>}</span>
        </div>

        <div className='main-container'>
          <div className='module main-left'>
            {(projectData.type === 'Range' || projectData.type === 'Start') &&
              <div>
                <div className='input-label'>Opens On</div>
                <div className='description'>{moment(projectData.start).format('LLLL')}</div>
              </div>
            }
            {(projectData.type === 'Range' || projectData.type === 'End') &&
              <div>
                <div className='input-label'>Ends On</div>
                <div className='description'>{moment(projectData.end).format('LLLL')}</div>
              </div>
            }
            <div className='input-label'>Description</div>
            <div className='description' dangerouslySetInnerHTML={{__html: projectData.description}}></div>
            <div className='input-label'>Guidelines</div>
            <div className='description' dangerouslySetInnerHTML={{__html: projectData.guidelines}}></div>
            {projectData && projectData.status == 'Active' && projectData.open && <button><Link to={`/publication/${id}/${project}/submit`}>Submit</Link></button>}
            {projectData && userIsOwner && projectData.status == 'Hidden' && projectData.open && <button><Link to={`/publication/${id}/${project}/submit`}>Submit</Link></button>}
          </div>
          <div className='sidebar-right'>
            <div className='module w-100'>
              <div className='input-label'>About {publication.name}</div>
              <div className='description' dangerouslySetInnerHTML={{__html: publication.description}}></div>

              <div className='profile-link'><Link to={`/publication/${id}`}>Profile</Link></div>
              <div className='profile-link'><a href={publication.website} target='_blank'>Official Website</a></div>
              <div className='profile-link'><a href={`https://twitter.com/${publication.twitter}`} target='_blank'>Twitter</a></div>
            </div>
          </div>
        </div>
      </div>
      }
    </div>
  )
}
