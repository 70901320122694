import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import moment from 'moment';

export default function PublicationHome({ sessionValid, urlBase }) {
  const [profile, setProfile] = useState({});
  const [projects, setProjects] = useState([]);
  const [userIsOwner, setUserIsOwner] = useState(false);
  const [error, setError] = useState('');

  let { id } = useParams();

  useEffect(() => {
    getPublicationProfile();
   }, []);

   const getPublicationProfile = async () => {
     const token = localStorage.getItem('firmamentToken');
     let data = await fetch(`${urlBase}get-pub-profile?pub=${id}&token=${token}`);
     let response = await data.json();
     console.log(response);
     if (response.status === 'error') {
       setError(response.message);
     } else {
       setProfile(response.profile);
       setUserIsOwner(response.isOwner || response.isEditor);
       setProjects(response.projects)
     }
   }

   const isOpen = (project) => {
     if (project.type === "Rolling" || project.type == "Quota") {
       return true;
     } else if (project.type == "Start") {
       //
       if (moment().isAfter(project.start)) {
         return true;
       } else {
         return false;
       }
     } else if (project.type == "End") {
       if (moment().isBefore(project.end)) {
         return true;
       } else {
         return false;
       }
     } else if (project.type == "Range") {
       if (moment().isAfter(project.start) && moment().isBefore(project.end)) {
         return true;
       } else {
         return false;
       }
     }
   }

   const isOpening = (project) => {
     if (project.type === "Range" || project.type === "Start") {
       return moment().isBefore(project.start);
     } else {
       return false;
     }
   }


  return (
    <div>
      <div className='page-header with-logo'>
        {profile.logo && <div className='pub-logo'><img src={profile.logo} /></div>}
        <div className='page-info'>
          {profile.city && <div className='meta-info'>{profile.city}{profile.country && <span>, {profile.country}</span>}</div>}
          {profile.name && <h1>{profile.name}</h1>}
          <div class='site-links'>
            {userIsOwner && <Link to={`/publication/${id}/manage`}>Manage</Link>}
            {profile.website && <a href={profile.website} target='_blank' >Official Website</a> }
            {profile.twitter && <a href={`https://twitter.com/${profile.twitter}`} target='_blank' >Official Twitter</a> }
            {profile.instagram && <a href={`https://instagram.com/${profile.instagram}`} target='_blank' >Official Instagram</a> }
          </div>
        </div>
      </div>

      <div className='main-container'>
        <div className='module full'>
          <div className='input-label'>About Us</div>
          <div className='description' dangerouslySetInnerHTML={{__html: profile.description}}></div>
        </div>
      </div>
      <h2>Projects</h2>
      <div className='project-grid'>
      {projects.length == 0 && <div className="fyi">This publisher has no active projects.</div>}
      {projects && projects.map((project) =>
            <div key={project._id} className='project-module large'>
              {isOpen(project) && <div className='project-status-pill open'>Now Open</div>}
              {isOpening(project) && <div className='project-status-pill soon'>Opens Soon</div>}
              {!isOpen(project) && !isOpening(project) && <div className='project-status-pill closed'>Closed</div>}
              <div className='project-title'><Link to={`/publication/${id}/${project.slug}`}>{project.name}</Link></div>
              {project.type == 'Rolling' && <div className='project-status'>Open</div>}
              {project.type == 'Start' && <div className='project-status'>Open after {moment(project.start).format('LLL')}</div>}
              {project.type == 'End' && <div className='project-status'>Open until {moment(project.end).format('LLL')}</div>}
              {project.type == 'Range' && <div className='project-status'>Open from {moment(project.start).format('LLL')} to {moment(project.end).format('LLL')}</div>}
              {project.type == 'Quota' && <div className='project-status'>Open up to {project.quota} submissions</div>}
              <div className='project-description' dangerouslySetInnerHTML={{__html: project.description}}></div>
              <div className='project-buttons'>
                <button class='secondary'><Link to={`/publication/${id}/${project.slug}`}>View</Link></button>
                {isOpen(project) && <button class='secondary'><Link to={`/publication/${id}/${project.slug}/submit`}>Submit</Link></button>}
              </div>
            </div>
        )}
      </div>
      {error && <span>{error}</span>}
    </div>
  )
}
