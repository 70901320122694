import React, { useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';

export default function SubsTable({context, project, pub, anonymous, urlBase, query}) {
  const [sort, setSort] = useState('date-old');
  const [filter, setFilter] = useState('');
  const [submissions, setSubmissions] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState();
  const [pages, setPages] = useState([]);

  const token = localStorage.getItem('firmamentToken');
  let history = useHistory();

  const getInitials = (name) => {
    let parts = name.split(' ');
    let reducer = (accumulator, currentValue) => accumulator.charAt(0) + currentValue.charAt(0);
    return parts.length > 1 ? parts.reduce(reducer) : name.charAt(0).toUpperCase();
  }

  const getSubs = async (thesort, per, pg, filter) => {
    let view = filter ? filter : '';
    let response = await fetch(`${urlBase}user-subs?token=${token}&per=${per}&page=${pg}&filter=${view}${thesort == 'date-recent' ? '&descending=true' : ''}`);
    let data = await response.json();
    setSubmissions(data.subs);
    calcPages(data.total, per);
  }

  const getEditSubs = async (thesort, per, pg, filter) => {
    let view = filter ? filter : '';
    let response = await fetch(`${urlBase}project-subs?token=${token}&project=${project}&pub=${pub}&per=${per}&page=${pg}&filter=${view}${thesort == 'date-recent' ? '&descending=true' : ''}`);
    let data = await response.json();
    setSubmissions(data.subs);
    calcPages(data.total, per);
  }

  const calcPages = (total, per) => {
    console.log(total, per)
    setTotal(total);
    if (total < per) {
      setPages([]);
    } else {
      let count = Math.ceil(total / per);
      let newPages = [];
      for (let i = 0; i < count; i++) {
        newPages.push({
          page: i,
          display: (i + 1)
        })
      };
      setPages(newPages);
    }
  }

  const requestVersion = context == 'user' ? getSubs : getEditSubs;
  const viewBase = context == 'user' ? 'my-submissions' : `publication/${pub}/${project}/submissions`

  useEffect(() => {
    console.log('Got here');
    let theSort = query.get("sort") ? query.get("sort") : 'date-oldest';
    let per = query.get("perPage") ? query.get("perPage") : localStorage.getItem('oleada_per') ? localStorage.getItem('oleada_per') : 20;
    let thePage = query.get("page") ? query.get("page") : 1;
    let theFilter = query.get("filter") ? query.get("filter") : '';

    setSort(theSort);
    setPerPage(parseInt(per));
    setPage(parseInt(thePage) - 1);
    setFilter(theFilter);
    requestVersion(theSort, per, (parseInt(thePage) - 1), theFilter);
  }, [])


  const changeSort = (newSort) => {
    if (newSort !== sort) {
      setSort(newSort);
      history.push(`/${viewBase}?sort=${newSort}&filter=${filter}&perPage=${perPage}&page=${page + 1}`);
      requestVersion(newSort, perPage, page, filter);
    }
  }

  const changePerPage = (newPer) => {
    if (newPer !== perPage) {
      setPerPage(parseInt(newPer));
      localStorage.setItem('oleada_per', newPer);
      history.push(`/${viewBase}?sort=${sort}&filter=${filter}&perPage=${newPer}&page=${page + 1}`);
      requestVersion(sort, parseInt(newPer), 0, filter);
    }
  }

  const goToPage = (newPage) => {
    if (newPage !== page) {
      setPage(parseInt(newPage));
      history.push(`/${viewBase}?sort=${sort}&filter=${filter}&perPage=${perPage}&page=${newPage + 1}`);
      requestVersion(sort, perPage, newPage, filter);
    }
  }

  const changeFilter = (newFilter) => {
    if (newFilter !== filter) {
      setFilter(newFilter);
      history.push(`/${viewBase}?sort=${sort}&filter=${newFilter}&perPage=${perPage}&page=${page + 1}`);
      requestVersion(sort, perPage, page, newFilter);
    }
  }

  return(
    <div>
      <div className='module table-header'>
        <div></div>
        <div>
        <div className='sort-control'>
          <span className='sort-label'>Per Page:</span>
          <select value={perPage} onChange={(e) => {changePerPage(e.target.value)}}>
            <option value='10'>10</option>
            <option value='20'>20</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
          </select>
        </div>
          {context == 'user' &&
          <div className='sort-control'>
            <span className='sort-label'>Filter By:</span>
            <select value={filter} onChange={(e) => {changeFilter(e.target.value)}}>
              <option value=''>All</option>
              <option value='submitted'>Submitted</option>
              <option value='progress'>In Progress</option>
              <option value='declined'>Declined</option>
              <option value='accepted'>Accepted</option>
              <option value='withdrawn'>Withdrawn</option>
            </select>
          </div>}
          {context == 'edit' &&
          <div className='sort-control'>
            <span className='sort-label'>Filter By:</span>
            <select value={filter} onChange={(e) => {changeFilter(e.target.value)}}>
              <option value=''>All</option>
              <option value='submitted'>Submitted</option>
              <option value='quick'>Quick Read</option>
              <option value='close'>Close Read</option>
              <option value='accepted'>Accepted</option>
              <option value='declined'>Declined</option>
              <option value='withdrawn'>Withdrawn</option>
            </select>
          </div>}
          <div className='sort-control'>
            <span className='sort-label'>Sort By:</span>
            <select value={sort} onChange={(e) => {changeSort(e.target.value)}}>
              <option value='date-old'>Date (oldest first)</option>
              <option value='date-recent'>Date (recent first)</option>
            </select>
          </div>
        </div>
      </div>
      <div className='subs-table'>
        {context == 'edit' &&
          <div className='sub-table-header'>
            <span>Title</span>
            <span>Author</span>
            <span>Email</span>
            <span>Submitted Date</span>
            <span>Messages</span>
            <span>Status</span>
          </div>
        }
        {context == 'user' &&
          <div className='sub-table-header'>
            <span>Title</span>
            <span>Publication</span>
            <span>Project</span>
            <span>Submitted Date</span>
            <span>Status</span>
          </div>
        }
        {submissions && submissions.map(sub =>
          <div key={sub._id} className="sub-row">
            <span className='sub-title'>
              <Link to={`/submission/${sub._id}${context === 'edit' ? '/edit' : ''}`}>{sub.title}</Link>
              {context == 'edit' && sub.messages.length > 0 && <div className='message-bubble'><div className='message-initials initials'>{sub.messages.length}</div></div>}
            </span>
            {context == 'user' && <span>{sub.publication.name}</span>}
            {context == 'user' && <span>{sub.project.name}</span>}
            {context == 'edit' && !anonymous && <span>{sub.user.name}</span>}
            {context == 'edit' && anonymous && <span>Anonymous</span>}
            {context == 'edit' && !anonymous && <span>{sub.user.id}</span>}
            <span>{moment(sub.submittedDate).format('LLLL')}</span>
            {context == 'edit' &&
              <span>
              {sub.comments.length > 0 &&
                [...new Set(sub.comments.map(comment => comment.user.name))].map(comm => {
                  return (
                  <div key={comm} className='comment-bubble' title={`Commented on by ${comm}`} alt={`Commented on by ${comm}`}><div className='initials'>{getInitials(comm)}</div></div>
                  )
                })
              }
              </span>
            }
            <span>{sub.status}</span>
          </div>
        )}
      </div>
      {total == 0 && submissions.length && <div className='fyi'>You don't have any submissions yet</div>}
      <div className='table-bottom'>
        {total > 0 && submissions.length && <span>Showing {(1 + (page * perPage))}-{(submissions.length + (page * perPage))} of {total} {total == 1 ? 'submission' : 'submissions'}</span>}
        {total > perPage &&
          <div className='pagination'>
            {pages && pages.map((pg) =>
              <div key={pg.page} className={`pager ${pg.page === page && 'active'}`} onClick={() => {goToPage(pg.page)}}>{pg.display}</div>
            )}
          </div>
        }
      </div>
    </div>
  )
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  return ref.current;
}
