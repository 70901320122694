import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import trix from 'trix/dist/trix';
import { TrixEditor } from "react-trix";
import { ReactComponent as Loading } from '../../components/loading.svg';

const useForceUpdate = () => useState()[1];

export default function SubmissionPage({ sessionValid, urlBase }) {
  const { id } = useParams();
  const { project } = useParams();
  const [userData, setUserData] = useState({});
  const [projectData, setProjectData] = useState({});
  const [publicationData, setPublicationData] = useState({});
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [showGuidelines, setShowGuidelines] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  // Form fields
  const [title, setTitle] = useState('');
  const [name, setName] = useState('');
  const [bio, setBio] = useState('');
  const [coverLetter, setCoverLetter] = useState('');

  //
  const fileInput = useRef(null);
  const forceUpdate = useForceUpdate();
  const [file, setFile] = useState('');
  const { current } = fileInput;

  const [inputErrors, setInputErrors] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const fileName = () => {
    if (current && current.files.length > 0) {
      return current.files[0].name;
    }
    return null;
  }

  let history = useHistory();

  useEffect(() => {
    if (sessionValid && !loading && !userData._id) {
      setLoading(true);
      populateData();
    }
  });

  const populateData = async () => {
    const token = localStorage.getItem('firmamentToken');
    let response = await fetch(`${urlBase}get-submit-page?pub=${id}&project=${project}&token=${token}`);
    let data = await response.json();
    setUserData(data.user);
    setName(data.user.name);
    setBio(data.user.bio);
    setProjectData(data.project);
    setPublicationData(data.publication);
    setLoading(false);
  }

  const submit = async () => {
    const fileLocation = await uploadFile();
    setSubmitting(true);
    const token = localStorage.getItem('firmamentToken');
    const subData = {
      project: {
        name: projectData.name,
        id: projectData._id,
        slug: projectData.slug
      },
      publication: {
        name: publicationData.name,
        id: publicationData._id,
        slug: publicationData.slug
      },
      user: {
        name: name,
        email: userData.email,
        id: userData._id,
        bio: bio,
      },
      title: title,
      token: token,
      coverLetter: coverLetter,
      bio: bio,
      submittedDate: new Date(),
      edStatus: 'Submitted',
      status: 'Submitted',
      file: fileLocation,
      comments: [],
      messages: []
    }
    const settings = {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(subData)
    };
    const response = await fetch(`${urlBase}submit`, settings);
    const data = await response.json();
    setSubmitting(false);
    if (data.ok) {
      history.push('/my-submissions?success');
    } else {
      console.log('error');
    }
  }

  const uploadFile = async () => {
    setUploading(true);
    let originalName = fileName();
    let originalParts = originalName.split('.');
    let extension = originalParts[originalParts.length - 1];
    const { current } = fileInput;
    let file = current.files[0];
    const formData = new FormData();
    formData.append('file', file);
    let response = await fetch(`${urlBase}file?pub=${id}&project=${project}&ext=${extension}`, {
      method: 'post',
      body: formData,
    });
    let data = await response.json();
    return data.file;
    setUploading(false);
  }

  const checkSubmit = () => {
    let errorFields = [];
    let thisValid = true;
    if (name.length == 0) {
      errorFields.push('name');
      thisValid = false;
    }
    let strippedBio = bio.replace(/(<([^>]+)>)/ig,"");
    if (projectData.requireBio && strippedBio.length == 0) {
      errorFields.push('bio');
      thisValid = false;
    }
    if (projectData.requireCover && coverLetter.length == 0) {
      errorFields.push('cover-letter');
      thisValid = false;
    }
    if (title.length == 0) {
      errorFields.push('title');
      thisValid = false;
    }
    if (!current || current.files.length == 0) {
      errorFields.push('file');
      thisValid = false;
    }
    if (current && current.files.length > 0) {
      let filename = current.files[0].name;
      let parts = filename.split('.');
      let extension = parts[parts.length - 1].toLowerCase();
      if (projectData.filetypes.indexOf(extension) == -1) {
        errorFields.push('filetype');
        thisValid = false;
      }
    }
    if (thisValid) {
      setInputErrors([]);
      submit();

    } else {
      setInputErrors(errorFields);
    }

  }

  return (
    <div>
      {!sessionValid && <span>You must be logged in to submit. Please <Link to={`/login?redirect=publication/${id}/${project}/submit`}>Login</Link> or <Link to={`/create-account?redirect=publication/${id}/${project}/submit`}>Create An Account</Link>.</span>}
      {sessionValid && !loading && userData._id &&
        <div>
          <h1>New Submission for {projectData.name} (by {publicationData.name})</h1>
          <div className='main-container'>
            <div className='main-left module'>
              <div className='input-label'>Title</div>
              <input className={`w-40 ${inputErrors.indexOf('title') !== -1 && 'input-error'}`} value={title} onChange={(e) => {setTitle(e.target.value)}} disabled={uploading || submitting ? 'disabled' : ''} />
              <div className='input-label'>Name</div>
              <input className={`w-40 ${inputErrors.indexOf('name') !== -1 && 'input-error'}`} value={name} onChange={(e) => {setName(e.target.value)}} disabled={uploading || submitting ? 'disabled' : ''} />
              <div className='input-label'>Biography</div>
              <TrixEditor className={`${inputErrors.indexOf('bio') !== -1 && 'input-error'}`} value={bio} onChange={(html, text) => setBio(html)} />
              <div className='input-label'>Cover Letter / Comments</div>
              <TrixEditor className={`${inputErrors.indexOf('cover-letter') !== -1 && 'input-error'}`}  onChange={(html, text) => setCoverLetter(html)} />
              <div className='input-label'>Upload a File ({projectData.filetypes.join(', ')})</div>
              <input type='file' type='file' ref={fileInput} onChange={forceUpdate} disabled={uploading || submitting ? 'disabled' : ''} />
              {!uploading && !submitting &&
                <label htmlFor="file" className={`file-upload ${inputErrors.indexOf('file') !== -1 && 'input-error'}`} onClick={() => {fileInput.current.click()}}>
                  <span tabIndex="0" role="button" aria-controls="filename">
                    Select File
                  </span>
                </label>
              }
              <div className='file-name'>{fileName()}</div>
              {inputErrors.indexOf('filetype') !== -1 && <div class='error'>That is not a valid filetype for this project.</div>}

              <div className='form-bottom'>
              {!submitting && !uploading && <button onClick={checkSubmit}>Submit</button>}
              {uploading && !submitting && <div className='loading-icon'><Loading />Uploading...</div>}
              {submitting && <div className='loading-icon'><Loading />Submitting...</div>}
              </div>
            </div>

            <div className='sidebar-right'>
              <div className='module w-100'>
                <div className='input-label'>Guidelines</div>
                <div className='description' dangerouslySetInnerHTML={{__html: projectData.guidelines}}></div>
              </div>
            </div>

          </div>
        </div>
      }
    </div>
  )
}
