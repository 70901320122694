import React, { useState } from 'react';
import { useHistory, Link } from "react-router-dom";

export default function ForgotPassword({ checkSession, sessionValid, logout, urlBase }) {
  const [email, setEmail] = useState('');
  const [checking, setChecking] = useState(false);
  const [error, setError] = useState('');
  const [done, setDone] = useState(false);

  const request = async () => {
    const response = await fetch(`${urlBase}forgot?email=${email}`);
    const data = await response.json();
    if (data.status == 'error') {
      setError(data.message);
    } else {
      setDone(true);
    }
  }

  return (
    <div className='main-container'>
      {sessionValid &&
        <div>You are already logged in. Vist <Link to={'/my-account'}>your account</Link> page.</div>
      }
      {!sessionValid && !done &&
        <div className='module w-40 auth'>
          <h1 className='auth-header'>Request Password Reset</h1>

          <div className='input-label'>Email</div>
          <input className='w-100' value={email} onChange={(e) => {setEmail(e.target.value)}} />

          {!checking && <button onClick={request}>Request</button>}
          {error && <span className='error'>{error}</span>}
        </div>
      }
      {done &&
        <div className='module w-40 auth'>
          Success! Check your email for a link to reset your password.
        </div>
      }
    </div>
  )
}
