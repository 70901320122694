import React, {useEffect, useState} from 'react';
import { useParams, Link } from "react-router-dom";
import trix from 'trix/dist/trix';
import { TrixEditor } from "react-trix";
import moment from 'moment';
import Datetime from 'react-datetime';

export default function EditProject({ sessionValid, urlBase }) {
  const [userCanEdit, setUserCanEdit] = useState(false);
  const [projectData, setProjectData] = useState({});
  const [publication, setPublication] = useState({});
  const [editing, setEditing] = useState(false);

  const [ newDescription, setNewDescription] = useState('');
  const [newGuidelines, setNewGuidelines] = useState('');
  const [ newStatus, setNewStatus ] = useState('');
  const [ newFiletypes, setNewFiletypes ] = useState([]);
  const [ newAnonymous, setNewAnonymous] = useState([]);

  const [newTitle, setNewTitle] = useState('');
  const [ newType, setNewType] = useState('');
  const [newStart, setNewStart] = useState('');
  const [newEnd, setNewEnd] = useState('');
  const [newQuota, setNewQuota] = useState(1);
  const [newDecline, setNewDecline] = useState('');
  const [newEmails, setNewEmails] = useState([]);
  const [newReqBio, setNewReqBio] = useState();
  const [newReqCover, setNewReqCover] = useState();
  const [possibleEmails, setPossibleEmails] = useState([])

  const { id } = useParams();
  const { project } = useParams();
  const token = localStorage.getItem('firmamentToken');

  const getProjectProfile = async () => {
    let response = await fetch(`${urlBase}get-project-edit?pub=${id}&project=${project}&token=${token}`);
    let data = await response.json();
    if (data.isOwner || data.isEditor) {
      setUserCanEdit(true);
      setProjectData(data.project);
      setPublication(data.publication);
      let eds = data.publication.editors;
      eds.push(data.publication.owner);
      setPossibleEmails(eds);
    }
  }

  useState(() => {
    getProjectProfile();
  }, [])

  const toggleEditing = () => {
    if (!editing) {
      setNewStatus(projectData.status);
      setNewTitle(projectData.name);
      setNewAnonymous(projectData.anonymous);
      setNewDescription(projectData.description);
      setNewGuidelines(projectData.guidelines);
      setNewQuota(projectData.quota);
      setNewReqBio(projectData.requireBio);
      setNewReqCover(projectData.requireCover);
      setNewDecline(projectData.standardDecline);
      setNewStart(projectData.start ? new Date(projectData.start) : new Date());
      setNewEnd(projectData.end ? new Date(projectData.end) : new Date());
      setNewType(projectData.type);
      setNewEmails({
        active: projectData.notifyEmails ? projectData.notifyEmails : [],
        possible: possibleEmails
      });
      setNewFiletypes({
        allowed: projectData.filetypes,
        possible: ['pdf', 'doc', 'docx', 'rtf', 'txt', 'jpg', 'png', 'mp3']
      })
      setEditing(true);
    } else {
      setEditing(false);
    }
  }

  const saveChanges = async () => {
    const changes = {
      id: projectData._id,
      name: newTitle,
      token: token,
      status: newStatus,
      description: newDescription,
      guidelines: newGuidelines,
      anonymous: newAnonymous,
      filetypes: newFiletypes.allowed,
      standardDecline: newDecline,
      notifyEmails: newEmails.active,
      requireBio: newReqBio,
      requireCover: newReqCover,
      quota: newQuota,
      end: newEnd,
      start: newStart,
      type: newType
    }
    const settings = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(changes)
    };
    const response = await fetch(`${urlBase}update-project`, settings);
    const data = await response.json();
    setEditing(false);
    getProjectProfile();
  }

  const possible = ['pdf', 'doc', 'docx', 'rtf', 'txt', 'jpg', 'png', 'mp3'];

  const toggleFiletype = (type) => {
    const exists = newFiletypes.allowed.indexOf(type) == -1 ? false : true;
    let newestFiletypes = newFiletypes.allowed;
    if (exists) {
      newestFiletypes = newestFiletypes.filter((value, index, arr) => { return value !== type;});
    } else {
      newestFiletypes.push(type);
    }
    setNewFiletypes({
      allowed: newestFiletypes,
      possible: possible
    });
  }

  const toggleEmail = (email) => {
    const exists = newEmails.active.indexOf(email) == -1 ? false : true;
    let newestEmails = newEmails.active;
    if (exists) {
      newestEmails = newestEmails.filter((value, index, arr) => { return value !== email;});
    } else {
      newestEmails.push(email);
    }
    setNewEmails({
      active: newestEmails,
      possible: possibleEmails
    });
  }

  return (
    <div>
      {(!sessionValid || !userCanEdit) && <span>You must <Link to={`/login?redirect=publication/${id}/${project}/manage`}>login</Link> to view this page</span>}
      {userCanEdit &&
        <div>
          <div className='page-header'>
            <div className='meta-info'>Created {moment(projectData.created).format('LLL')}</div>
            <h1>{editing ? newTitle : projectData.name}</h1>
            <div><Link to={`/publication/${id}/${project}`}>View</Link> | <Link to={`/publication/${id}/${project}/submissions`}>View Submissions</Link></div>
          </div>
          <div className="main-container">
            <div className='module main-left'>
              {!editing &&
                <div>
                  <div className='input-label'>Status</div>
                  {projectData.status && <div className='description'>{projectData.status}</div>}
                  <div className='input-label'>Type</div>
                  <div className='description'>{projectData.type}</div>

                  {(projectData.type === 'Start' || projectData.type === 'Range') &&
                    <div>
                      <div className='input-label'>Start Date/Time</div>
                      <div className='description'>{moment(projectData.start).format('LLL')}</div>
                    </div>
                  }
                  {(projectData.type === 'End' || projectData.type === 'Range') &&
                    <div>
                      <div className='input-label'>End Date/Time</div>
                      <div className='description'>{moment(projectData.end).format('LLL')}</div>
                    </div>
                  }
                  {projectData.type === 'Quota' &&
                    <div>
                      <div className='input-label'>Quota</div>
                      <div className='description'>{projectData.quota}</div>
                    </div>
                  }
                  <div className='input-label'>Description</div>
                  <div className='description' dangerouslySetInnerHTML={{__html: projectData.description}}></div>
                  <div className='input-label'>Guidelines</div>
                  <div className='description' dangerouslySetInnerHTML={{__html: projectData.guidelines}}></div>
                  <div className='input-label'>Standard Decline Message</div>
                  {projectData.standardDecline ?
                    <div className='description' dangerouslySetInnerHTML={{__html: projectData.standardDecline}}></div> :
                    <div className='description'>--</div>
                  }
                  <div className='input-label'>Anonymize Submissions</div>
                  <div className='description'>{projectData.anonymous ? 'Yes' : 'No'}</div>

                  <div className='input-label'>Require Bio</div>
                  <div className='description'>{projectData.requireBio ? 'Yes' : 'No'}</div>

                  <div className='input-label'>Require Cover Letter</div>
                  <div className='description'>{projectData.requireCover ? 'Yes' : 'No'}</div>
                  <div className='input-label'>Allowed Filetypes</div>
                  {projectData.filetypes && <div className='description'>{projectData.filetypes.join(', ')}</div>}

                  <div className='input-label'>Email List</div>
                  <div className='fyi'>These users will receive an email when a new submission arrives.</div>
                  <div class='description'>{projectData.notifyEmails && projectData.notifyEmails.length > 0 ? projectData.notifyEmails.join(', ') : '--'}</div>

                  <button onClick={toggleEditing}>Edit</button>
                </div>
              }
              {editing &&
                <div>

                  <div className='input-label'>Title</div>
                  <input value={newTitle} onChange={(e) => setNewTitle(e.target.value)} />

                  <div className='input-label'>Status</div>
                  <select value={newStatus} onChange={(e) => setNewStatus(e.target.value)}>
                    <option value='Active'>Active</option>
                    <option value='Paused'>Paused</option>
                    <option value='Hidden'>Hidden</option>
                  </select>
                  {newStatus === 'Active' && <span className='fyi'>Users will be able to view the project.</span>}
                  {newStatus === 'Paused' && <span className='fyi'>Users will be able to view the project, but will not be able to submit until the project is changed to Active.</span>}
                  {newStatus === 'Hidden' && <span className='fyi'>Only you will be able to see this project.</span>}


                  <div className='input-label'>Submission Type</div>
                  <select value={newType} onChange={(e) => setNewType(e.target.value)}>
                    <option value='Rolling'>Rolling</option>
                    <option value='Quota'>Quota</option>
                    <option value='Range'>Time Range</option>
                    <option value='Start'>Start Date</option>
                    <option value='End'>End Date</option>
                  </select>
                  {newType === 'Rolling' && <span className='fyi'>Submissions are open by default until manually closed.</span>}
                  {newType === 'Quota' && <span className='fyi'>Submissions are open until a threshold is hit, i.e. 100 submissions.</span>}
                  {newType === 'Range' && <span className='fyi'>Submissions are open only between the date/times selected.</span>}
                  {newType === 'Start' && <span className='fyi'>Submissions are open after the date/time selected.</span>}
                  {newType === 'End' && <span className='fyi'>Submissions are open until the date/time selected.</span>}
                  {newType === 'Quota' &&
                    <div>
                      <div className='input-label'>Quota</div>
                      <input type='number' value={newQuota} onChange={(e) => setNewQuota(e.target.value)} />
                    </div>
                  }
                  {(newType == "Range" || newType == "Start") &&
                    <div>
                      <div className='input-label'>Start Date/Time</div>
                      <Datetime value={newStart} onChange={setNewStart} />
                    </div>
                  }
                  {(newType == "Range" || newType == "End") &&
                    <div>
                      <div className='input-label'>End Date/Time</div>
                      <Datetime value={newEnd} onChange={setNewEnd}/>
                    </div>
                  }


                  <div className='input-label'>Description</div>
                  <TrixEditor value={newDescription} onChange={(html, text) => setNewDescription(html)} />

                  <div className='input-label'>Guidelines</div>
                  <TrixEditor value={newGuidelines} onChange={(html, text) => setNewGuidelines(html)} />

                  <div className='input-label'>Standard Decline Message</div>
                  <TrixEditor value={newDecline} onChange={(html, text) => setNewDecline(html)} />

                  <div className='input-label'>Anonymize Submissions</div>
                  <input type="checkbox" checked={newAnonymous} onChange={(e) => setNewAnonymous(e.target.checked)} />

                  <div className='input-label'>Require Submitter Bio</div>
                  <input type="checkbox" checked={newReqBio} onChange={(e) => setNewReqBio(e.target.checked)} />

                  <div className='input-label'>Require Cover Letter</div>
                  <input type="checkbox" checked={newReqCover} onChange={(e) => setNewReqCover(e.target.checked)} />

                  <div className='input-label'>Allowed File Types</div>
                  {newFiletypes.possible.map((type) =>
                    <div key={type} onClick={() => toggleFiletype(type)} className={`editor-pill ${newFiletypes.allowed.indexOf(type) !== -1 && 'active'}`}>{type}</div>
                  )}

                  <div className='input-label'>Email List</div>
                  <div className='fyi'>These users will receive an email when a new submission arrives.</div>
                  {newEmails.possible.map((email) =>
                    <div key={email} onClick={() => toggleEmail(email)} className={`editor-pill ${newEmails.active.indexOf(email) !== -1 && 'active'}`}>{email}</div>
                  )}


                  <div className='controls bottom-controls'><button onClick={saveChanges}>Save</button> <button className='cancel' onClick={toggleEditing}>Cancel</button></div>
                </div>
              }
            </div>
          </div>
        </div>
      }
    </div>
  )
}
