import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import moment from 'moment';

export default function ViewSubmission({ sessionValid, urlBase}) {
  let { id } = useParams();
  const [sub, setSub] = useState({});
  const [error, setError] = useState('');
  const [viewing, setViewing] = useState(false);
  const [editing, setEditing] = useState(false);
  const [newStatus, setNewStatus] = useState('');
  const [newMessage, setNewMessage] = useState('');

  const token = localStorage.getItem('firmamentToken');

  useEffect(() => {
    if (token) {
      getSub();
    } else {
      setError('True')
    }
  }, [])

  const getSub = async () => {
    const token = localStorage.getItem('firmamentToken');
    let response = await fetch(`${urlBase}get-sub?token=${token}&id=${id}`);
    let data = await response.json();
    console.log(data);
    if (data.status === 'error') {
      setError(data.message)
    } else {
      setSub(data.sub);
    }
  }

  const saveStatus = async () => {
    let data = {
      token: token,
      status: newStatus,
      id: id
    };
    const settings = {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    };
    const response = await fetch(`${urlBase}change-status`, settings);
    const responseData = await response.json();
    if (responseData.status == 'good') {
      setEditing(false);
      setNewMessage('');
      getSub('');
    }
  }

  const toggleEditing = () => {
    if (!editing) {
      setEditing(true);
      setNewStatus('Withdrawn');
    } else {
      setEditing(false);
      setNewStatus('');
      setNewMessage('');
    }
  }

  const sendMessage = async () => {
    let data = {
      token: token,
      message: newMessage,
      editor: false,
      id: id
    };
    const settings = {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    };
    const response = await fetch(`${urlBase}add-message`, settings);
    const responseData = await response.json();
    if (responseData.status == 'good') {
      setNewMessage('');
      getSub('');
    }
  }

  const isImage = (fileName) => {
    let lower = fileName.toLowerCase();
    return lower.includes('.jpg') || lower.includes('.jpeg') || lower.includes('.png') || lower.includes('.gif');
  }

  return(
    <div>
      {sub._id && sessionValid &&
        <div>
          <div className='page-header'>
            <h1>"{sub.title}"</h1>
            <div>Submitted to {sub.publication.name} on {moment(sub.submittedDate).format('LLLL')}</div>
          </div>
          <div className='main-container'>
            <div className='module main-left'>

              <div className='input-label'>Submission Status {sub.status !== 'Withdrawn' && !editing && <a className='edit-link' onClick={toggleEditing}>Withdraw</a>}</div>
              {!editing &&
                <div>
                  <div className='description'>{sub.status}</div>
                </div>
               }
               {editing &&
                 <div>
                  <div className='description warn'>{sub.status}</div>
                  <div className='controls'><button onClick={saveStatus}>Withdraw</button> <button className='cancel' onClick={toggleEditing}>Cancel</button></div>
                 </div>
               }

              <div className='input-label'>Author</div>
              <div className='description'>{sub.user.name}</div>
              <div className='input-label'>Email</div>
              <div className='description'>{sub.user.id}</div>
              <div className='input-label'>Cover Letter</div>
              <div className='description' dangerouslySetInnerHTML={{__html: sub.coverLetter}}></div>
              <div className='input-label'>Bio</div>
              <div className='description' dangerouslySetInnerHTML={{__html: sub.bio}}></div>
              <div className='input-label'>Submission File</div>
              <div className='description'>
                <button className='secondary inline' onClick={() => {setViewing(viewing ? false : true)}}><a>{viewing ? 'Hide' : 'Preview'}</a></button>
                <button className='secondary inline'><a href={sub.fileLocation} download target='_blank'>Download</a></button>
              </div>
              {viewing &&
                <div>
                  {isImage(sub.fileLocation) ? <img src={sub.fileLocation} width="80%" height="800" />: <iframe src={`https://docs.google.com/gview?url=${encodeURIComponent(sub.fileLocation)}&embedded=true`} width="80%" height="800" />}
                </div>
              }
            </div>
          <div className='sidebar-right'>
            <div className='module full'>
               <div className='input-label'>Messages With {sub.publication.name}</div>
               {(sub.messages.length > 0 || sub.status == 'Withdrawn') &&
                 <div>
                  <textarea className='w-100 message-box' value={newMessage} onChange={(e) => setNewMessage(e.target.value)} placeholder={`Send a message to ${sub.publication.name}`} />
                  {newMessage.length > 0 && <button onClick={sendMessage}>Send</button>}
                </div>
               }
               {sub.messages.length > 0 && sub.messages.sort((a,b) => {
                 return new Date(b.time) - new Date(a.time);
               }).map(message =>
                 <div key={message.time} className={`message ${message.user.editor && 'highlight'}`}>
                   <div className='comment-text' dangerouslySetInnerHTML={{__html: message.text}}></div>
                   <div className='comment-meta'>by {message.user.name} on {moment(message.time).format('LLLL')}</div>
                 </div>
               )}
               {sub.messages.length == 0 && <div className='fyi'>There are no messages yet.</div>}
                     </div>
             </div>
          </div>




        </div>
      }
      {error && <span>You do not have access to view this submission. Try <Link to={`login?redirect=submission/${id}`}>logging in</Link></span>}
    </div>
  )
}
